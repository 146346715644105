import { useEffect, useState } from 'react';
import { WrapperPage } from 'shared/components/WrapperPage/WrapperPage';
import { OutcomesButton } from './styles';

import { Button } from 'shared/components/Button/Button';
import { Outcomes } from './components/Outcomes/Outcomes';
import { ActiveEstablishments } from './components/ActiveEstablishments/ActiveEstablishments';
import { Dashboards } from './components/Dashboards/Dashboards';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import { toast } from 'react-toastify';

import api from '../../services/api';

interface ReportsProps {
  input: string;
  output: string;
}

interface BillingsProps {
  input: Array<{
    month: string;
    value: string;
  }>;
  output: Array<{
    month: string;
    value: string;
  }>;
}

export const Home = () => {
  const { data } = useAuth();
  const [revenues, setRevenues] = useState(0);
  const [reports, setReports] = useState<ReportsProps>();
  const [billing, setBilling] = useState<BillingsProps>();
  const [monthFilter, setMonthFilter] = useState<number>(0);
  const [monthYearLabel, setMonthYearLabel] = useState('Período Total');
  const [month, setMonth] = useState('');
  const [modalCalendar, setModalCalendar] = useState(false);
  const [modalCalendarDate, setModalCalendarDate] = useState<any>();
  const [rangeData, setRangeData] = useState(false);
  const [loadingReports, setLoadingReports] = useState<boolean>();
  const [loadingBilling, setLoadingBilling] = useState<boolean>();

  async function getReports() {
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      setLoadingReports(true);
      const res = await api.get(`/outputs/reports`, {
        headers,
      });
      setReports(res.data);
      setLoadingReports(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      setLoadingReports(false);
    }
  }

  function getMonthNumber() {
    if (monthFilter < 10) {
      const month = '' + 0 + monthFilter;
      return month;
    } else if (monthFilter >= 10) {
      return monthFilter;
    }
  }

  async function getReportsFilter() {
    const actualDate = new Date();
    const actualYear = actualDate.getFullYear();

    const monthNumber = getMonthNumber();

    if (rangeData && modalCalendarDate !== undefined) {
      const startDate = `${actualYear}-${monthNumber}-01T00:00:00-03:00`;
      const endDate = `${actualYear}-${monthNumber}-31T23:59:59-03:00`;

      const startDateCalendar =
        modalCalendarDate && modalCalendarDate[0]?.toISOString();
      const endDateCalendar =
        modalCalendarDate && modalCalendarDate[1]?.toISOString();

      const dateCalendarMonth =
        modalCalendarDate && modalCalendarDate[0].getMonth();

      if (monthNumber !== '00' || modalCalendar) {
        if (!modalCalendar) {
          setMonthYearLabel(`${month}/${actualYear}`);
        } else {
          setMonthYearLabel(
            `${SelectOptions[dateCalendarMonth]}/${actualYear}`,
          );
        }

        try {
          const headers = { Authorization: `Bearer ${data.accessToken}` };
          setLoadingReports(true);
          const res = await api.get(
            `/outputs/reports?${
              modalCalendar
                ? `startDate=${startDateCalendar}&endDate=${endDateCalendar}`
                : `startDate=${startDate}&endDate=${endDate}`
            }`,
            {
              headers,
            },
          );
          setReports(res.data);
          setLoadingReports(false);
        } catch (error) {
          toast.error(error.response?.data?.message || error.toString());
          setLoadingReports(false);
        }
      }
    } else if (modalCalendarDate) {
      const actualDate = new Date(modalCalendarDate);
      const actualDateIso = actualDate.toISOString();

      setMonthYearLabel(
        `${SelectOptions[actualDate.getMonth()]}/${actualDate.getFullYear()}`,
      );

      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };
        setLoadingReports(true);
        const res = await api.get(
          `/outputs/reports?startDate=${actualDateIso}`,
          {
            headers,
          },
        );
        setReports(res.data);
        setLoadingReports(false);
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
        setLoadingReports(false);
      }
    } else if (monthNumber !== '00') {
      const startDate = `${actualYear}-${monthNumber}-01T00:00:00-03:00`;
      const endDate = `${actualYear}-${monthNumber}-31T23:59:59-03:00`;

      setMonthYearLabel(`${month}/${actualYear}`);

      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };
        setLoadingReports(true);
        const res = await api.get(
          `/outputs/reports?${`startDate=${startDate}&endDate=${endDate}`}`,
          {
            headers,
          },
        );
        setReports(res.data);
        setLoadingReports(false);
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
        setLoadingReports(false);
      }
    }
  }

  async function getBillingHistory() {
    const actualDate = new Date();
    const actualDateIso = actualDate.toISOString();

    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      setLoadingBilling(true);
      const res = await api.get(
        `/outputs/billing-history?endDate=${actualDateIso}`,
        {
          headers,
        },
      );
      setBilling(res.data);
      setLoadingBilling(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      setLoadingBilling(false);
    }
  }

  async function getBillingHistoryFilter() {
    const actualDate = new Date();
    const actualYear = actualDate.getFullYear();
    const monthNumber = getMonthNumber();

    const startDate = `${actualYear}-${monthNumber}-01T00:00:00-03:00`;
    const endDate = `${actualYear}-${monthNumber}-31T00:00:00-03:00`;

    if (monthNumber !== '00' && modalCalendarDate === undefined) {
      setRevenues(1);
      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };
        setLoadingBilling(true);
        const res = await api.get(
          `/outputs/billing-history?${`startDate=${startDate}&endDate=${endDate}`}`,
          {
            headers,
          },
        );
        setBilling(res.data);
        setLoadingBilling(false);
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
        setLoadingBilling(false);
      }
    } else if (modalCalendarDate && !rangeData) {
      setRevenues(1);
      try {
        const actualDate = new Date(modalCalendarDate);
        const actualDateIso = actualDate.toISOString();

        const headers = { Authorization: `Bearer ${data.accessToken}` };
        setLoadingBilling(true);
        const res = await api.get(
          `/outputs/billing-history?endDate=${actualDateIso}`,
          {
            headers,
          },
        );
        setBilling(res.data);
        setLoadingBilling(false);
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
        setLoadingBilling(false);
      }
    } else if (rangeData) {
      setRevenues(1);

      const startDateCalendar =
        modalCalendarDate && modalCalendarDate[0]?.toISOString();

      const endDateCalendar =
        modalCalendarDate && modalCalendarDate[1]?.toISOString();

      const startDateCalendarMinusOne = new Date(startDateCalendar);
      const endDateCalendarMinusOne = new Date(endDateCalendar);

      setMonthYearLabel(
        `${
          SelectOptions[startDateCalendarMinusOne.getMonth()]
        }/${startDateCalendarMinusOne.getDate()}
        - ${
          SelectOptions[endDateCalendarMinusOne.getMonth()]
        }/${endDateCalendarMinusOne.getDate()}`,
      );

      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };
        setLoadingBilling(true);
        const res = await api.get(
          `/outputs/billing-history?${`startDate=${startDateCalendar}&endDate=${endDateCalendar}`}`,
          {
            headers,
          },
        );
        setBilling(res.data);
        setLoadingBilling(false);
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
        setLoadingBilling(false);
      }
    }
  }

  useEffect(() => {
    getReports();
    getBillingHistory();
  }, []);

  useEffect(() => {
    getReportsFilter();
    getBillingHistoryFilter();
  }, [monthFilter, modalCalendarDate, modalCalendar]);

  const SelectOptions = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return (
    <WrapperPage title="Dashboard">
      <Outcomes
        isEstablishment={false}
        setRevenues={setRevenues}
        setMonthFilter={setMonthFilter}
        setMonth={setMonth}
        setModalCalendar={setModalCalendar}
        setModalCalendarDate={setModalCalendarDate}
        rangeData={rangeData}
        setRangeData={setRangeData}
        SelectOptions={SelectOptions}
        placeholder="Data"
      />

      <OutcomesButton>
        <Link to="/configurar-saidas">
          <Button onClickFunction={() => {}}>
            <div>Configurar saídas</div>
          </Button>
        </Link>
      </OutcomesButton>

      <ActiveEstablishments />

      <Dashboards
        reports={reports}
        billing={billing}
        revenues={revenues}
        loadingReports={loadingReports}
        loadingBilling={loadingBilling}
        monthYearLabel={monthYearLabel}
      />
    </WrapperPage>
  );
};
