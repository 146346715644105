import styled from 'styled-components';

export const Background = styled.header`
  width: 100%;
  height: 100px;
  background-color: transparent;
`;

export const Container = styled.div`
  width: 100%;
  height: 100px;
  background-color: var(--backgroundHeader);
  border-top-left-radius: 55px;
  border-bottom-left-radius: 55px;

  @media (max-width: 1024px) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;

export const Title = styled.h1`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 2.5rem;
  color: var(--main);
  padding-top: 10px;
`;

export const MenuIcon = styled.img`
  width: 27px;
  height: 20px;
  cursor: pointer;
  margin-right: 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`;
