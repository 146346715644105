import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background-color: var(--main);

  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem;

    section{
      display: flex;
      flex-direction: column;

      img {
        max-width: 9.1rem;
        margin-bottom: 3.1rem;

        align-self: center;
      }

      h1 {
        font-size: 1.9rem;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 2.3rem;
      }

      span {
        color: #FFFFFF;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
    }
  }

  aside {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #FFFFFF;
    box-shadow: 0px 12px 12px #00000029;
    border-radius: 4.6rem 0 0 4.6rem;
  }

  @media (max-width: 1030px) {
    grid-template-columns: 100% 0;

    aside {
      display: none;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 26rem;

  .signInInput {
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 100px;
    border: none;

    padding: 1rem;
    height: 4.5rem;

    font-size: 1.45rem;
    text-align: center;

    background-size: 0px;
    background-repeat: no-repeat;
    background-position: 34% 0.9rem;

    & + .signInInput {
      margin-top: 1rem;
      background-position: 38% 1.5rem;
    }

    &::placeholder {
      color: #D1D1D6;
    }

    &:placeholder-shown {
      background-size: auto;
    }

    @media (max-width: 300px) {
      background-position: 24% 0.9rem;

      & + .signInInput {
        background-position: 30% 1.5rem;
      }
    }
  }

  div {
    display: flex;
    justify-content: space-between;

    margin: 1.8rem 0;
    padding: 0 0.5rem;

    span, a {
      color: #FFFFFF;
      font-size: 1.2rem;

      input[type="checkbox"] {
        border: 0 none;
        outline: 0;
        margin-right: 0.4rem;
      }
    }

    @media (max-width: 280px) {
      flex-direction: column;
      align-items: center;

      span {
        margin-bottom: 10px;
      }
    }
  }

  button {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 100px;
    border: none;

    padding: 1rem;
    height: 4rem;

    color: var(--main);
    font-size: 1.8rem;
    font-family: 'FontsFreeNetSFProTextMedium', sans-serif;

    transition: all 0.2s;

    &:hover {
      color: #FFFFFF;
      background-color: ${darken(0.1, '#21e270')};
    }
  }
`;
