import styled from 'styled-components';

export const Container = styled.div`
  .ant-select{
    cursor: pointer;
    background: #fff;
    border: 0;
    width: 100%;
    height: 50px;
    border-radius: 40px;
    padding: 10px 0;
    box-shadow: 0px 0px 6px 0px lightgrey;
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 16px;
    color: var(--main);
    outline: transparent;
    margin: 20px 0px;
    display: flex;
    align-items: center;

    @media (max-width: 450px) {
      min-width: 100%;
      margin: 0.5rem 0px;
    }
  }

  .ant-select-selection-placeholder {
    color: var(--main);
  }

  .ant-select-selector {
    border: 0 !important;
    border-radius: 40px !important;
    box-shadow: 0 !important;
    text-align: center;
  }

  .ant-select-arrow {
    color: var(--main);
    font-size: 1rem;
    width: 30px;
  }
`;
