import styled from 'styled-components';

export const OutcomesButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;

  button {
    max-width: 510px;
    width: 15rem;
    height: 40px;
    font-family: 'FontsFreeNetSFProTextMedium', sans-serif;
    font-size: 1.1rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50px;
  }

  @media (max-width: 1024px) {
    justify-content: center;
    margin-top: 0.5rem;

    button {
      width: 100%;
    }
  }
`;

export const ActiveEstablishment = styled.section`
  margin-top: -14px;
  width: 300px;
  display: flex;
  flex-direction: column;

  >div {
    z-index: 1 !important;
    display: flex;
    box-shadow: 0px 2px 6px #00000040;
    border-radius: 15px;
    padding: 8px 14px;
    align-items: center;
    color: #8E8E93;
    background-color: #FFFFFF;
    font-family: 'FontsFreeNetSFProTextBold', sans-serif;

    div {
      background: #FFB6C4;
      border-radius: 15px;
      padding: 7px;
      margin-right: 21px;
    }
  }

  button {
    max-width: 169px;
    margin-top: -20px;
    padding: 30px 31px 6px;
    border: none;
    background: #EFEFF4;
    font-family: 'FontsFreeNetSFProTextLight', sans-serif;
    color: #8E8E93;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 11px;
  }
`;
