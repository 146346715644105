import { useEffect, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { Select } from 'shared/components/Select/Select';
import { Container, Content, InputWrapper, Input } from './_Taxes';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';

import { useTaxesCommissions } from '../../../hooks/TaxesComissions';

const selectData = ['5min', '10min', '15min', '30min'];

interface Taxes {
  id: number;
  action: number;
  type: number;
  value: number;
}

export const Taxes = () => {
  const {
    transactions,
    setTransactions,
    tolerance,
    setTolerance,
    cancellation,
    setCancellation,
    rental,
    setRental,
  } = useTaxesCommissions();
  const { data } = useAuth();

  const [variables, setVariables] = useState<Taxes[]>([]);

  useEffect(() => {
    async function getvariables() {
      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };

        const res = await api.get('/variables', { headers });
        setVariables(res.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
      }
    }
    getvariables();
  }, []);

  useEffect(() => {
    variables.map((vari) => {
      if (vari.action === 1) {
        setTransactions(vari.value.toString());
      } else if (vari.action === 2) {
        setCancellation(vari.value.toString());
      } else if (vari.action === 3) {
        setTolerance(vari.value.toString());
      } else if (vari.action === 4) {
        setRental(vari.value.toString());
      }
    });
  }, [variables]);

  return (
    <Container>
      <h1>Taxas e Comissões</h1>

      <Content>
        <div>
          <div>
            <h2>Transação</h2>
            <InputWrapper>
              <div>
                <span>Comissão:</span>
                <Input>
                  <input
                    value={transactions}
                    onChange={(e) => setTransactions(e.target.value)}
                    type="text"
                  />
                  <span>%</span>
                </Input>
              </div>
            </InputWrapper>
          </div>
          <div>
            <h2>Maquininha</h2>
            <InputWrapper>
              <div>
                <span>Aluguel:</span>
                <Input>
                  <input
                    value={rental}
                    onChange={(e) => setRental(e.target.value)}
                    type="text"
                  />
                  <span>R$</span>
                </Input>
              </div>
            </InputWrapper>
          </div>
        </div>

        <h2>Cancelamento</h2>
        <InputWrapper>
          <div>
            <span>Comissão:</span>
            <Input>
              <input
                value={cancellation}
                onChange={(e) => setCancellation(e.target.value)}
                type="text"
              />
              <span>R$</span>
            </Input>
          </div>
          <div>
            <span>Tempo mínimo:</span>
            <Select
              data={selectData}
              onChange={(value) => {
                setTolerance(value);
              }}
              placeholder={tolerance + 'min'}
            ></Select>
          </div>
        </InputWrapper>
      </Content>
    </Container>
  );
};
