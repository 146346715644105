import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import FontsFreeNetSFProTextBold from '../fonts/FontsFree-Net-SFProText-Bold.ttf';
import FontsFreeNetSFProTextBoldItalic from '../fonts/FontsFree-Net-SFProText-BoldItalic.ttf';
import FontsFreeNetSFProTextHeavy from '../fonts/FontsFree-Net-SFProText-Heavy.ttf';
import FontsFreeNetSFProTextLight from '../fonts/FontsFree-Net-SFProText-Light.ttf';
import FontsFreeNetSFProTextMedium1 from '../fonts/FontsFree-Net-SFProText-Medium-1.ttf';
import FontsFreeNetSFProTextMedium from '../fonts/FontsFree-Net-SFProText-Medium.ttf';
import FontsFreeNetSFProTextSemibold1 from '../fonts/FontsFree-Net-SFProText-Semibold-1.ttf';

export const GlobalStyle = createGlobalStyle`
  /* Reset */
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

  @font-face {
    font-family: FontsFreeNetSFProTextBold;
    src: local('FontsFreeNetSFProTextBold'), url(${FontsFreeNetSFProTextBold});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextBoldItalic;
    src: local('FontsFreeNetSFProTextBoldItalic'), url(${FontsFreeNetSFProTextBoldItalic});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextHeavy;
    src: local('FontsFreeNetSFProTextHeavy'), url(${FontsFreeNetSFProTextHeavy});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextLight;
    src: local('FontsFreeNetSFProTextLight'), url(${FontsFreeNetSFProTextLight});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextMedium1;
    src: local('FontsFreeNetSFProTextMedium1'), url(${FontsFreeNetSFProTextMedium1});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextMedium;
    src: local('FontsFreeNetSFProTextMedium'), url(${FontsFreeNetSFProTextMedium});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextSemibold1;
    src: local('FontsFreeNetSFProTextSemibold1'), url(${FontsFreeNetSFProTextSemibold1});
  }

  :root {
    --main: #21E270;

    --green-300: #94FFC1;
    --red: #FF3B30;
    --orange: #FF9500;
    --yellow: #FFCC00;
    --blue: #007AFF;

    --backgroundHeader: #FCFCFD;
    --white: #FFFFFF;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: var(--main);
  }

  body, input, textarea, button, label {
    font-family: 'FontsFreeNetSFProTextLight', sans-serif;
  }

  h1 {
    font-family: 'FontsFreeNetSFProTextBold', sans-serif;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 5;
  }

  .react-modal-content {
    width: 100%;
    max-width: 543px;
    max-height: 546px;
    background: var(--white);
    box-shadow: 0px 2px 6px #00000052;

    padding: 15px;
    position: relative;
    border-radius: 33px;
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    font-size: 1.2rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
      cursor: pointer;
    }
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
`;
