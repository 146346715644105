import styled from 'styled-components';

export const CardContainer = styled.tr`
  color: #959599;
  font: 1rem 'FontsFreeNetSFProTextLight', sans-serif;
  border-bottom: 2px solid #E9E9E9;
  padding: 20px 0;
`;

export const CardHead = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 48px;
    max-height: 48px;
    margin-right: 1rem;
    border-radius: 14px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      max-height: 50px;
    }
  }

  section {
    font: 1rem 'FontsFreeNetSFProTextLight', sans-serif;
    color: #8E8E93;
    text-align: left;

    div {
      margin-top: 4px;

      svg {
        font-size: 1.2rem;
        color: #C7C7CC;

        &.active {
          color: #FFCC00;
        }
      }
    }
  }
`

export const CardAddress = styled.td`
  max-width: 118px;
  font-size: 0.8rem;
`;

export const CardOptions = styled.td`
  div {
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
      margin-right: 0.5rem;
      background-color: #C7C7CC;
      padding: 0.1rem;
      width: 2rem;
      height: 2rem;
      border-radius: 100%;

      &.active {
        background-color: var(--main);
      }

      img {
        width: 65%;
        height: 65%;
      }
    }

    .ant-switch {
      height: 28px;
      width: 56px;

      .ant-switch-handle {
        width: 23px;
        height: 23px;

        &::before {
          border-radius: 100%;
        }
      }
    }

    .ant-switch-checked {
      background-color: var(--main);

      .ant-switch-handle {
        left: calc(100% - 18px - 8px);
      }
    }
  }
`;

export const CardNotSolicitated = styled.div`
  background-color: #FF2D55;
  max-width: 120px;
  border-radius: 1rem;
  font-size: 0.7rem;
  color: var(--white);
  padding: 0.5rem 0;
  margin: 0 auto;

  img {
    margin-right: 0.5rem;
  }
`;

export const ContainerSpinner = styled.div`
  > div {
    margin: 0 auto !important;
    background-color: white !important;
  }
`;