import styled from 'styled-components';

export const CalendarContainer = styled.div`
  .react-calendar {
    border: none;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .react-calendar__viewContainer > div > div > div {
    display: flex;
    flex-direction: column-reverse;
  }

  abbr {
    font: 1.4rem 'FontsFreeNetSFProTextLight', sans-serif;
    color: #8E8E93;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    color: var(--main);
    cursor: default;
    text-decoration: none;
  }

  abbr[title="domingo"] {
    color: #FF2D55;
  }

  .react-calendar__tile--active {
    background: var(--main);
    color: var(--white);

    abbr{
      color: var(--white);
    }

    &:hover, &:focus {
      background: var(--main);
    }
  }

  .react-calendar__tile--now {
    background: #94FFC1;
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding: 0 1.2rem;

    >button {
      font: 1.4rem 'FontsFreeNetSFProTextBold', sans-serif;
      max-width: 52px;
    }
  }
`;

export const CompleteMonthSelector = styled.div`
  display: flex;
  align-items: center;

  strong {
    font: 1.2rem 'FontsFreeNetSFProTextBold', sans-serif;
  }

  button {
    width: 3rem;
    height: 3rem;
    background: transparent;
    margin-right: 0.5rem;
    border: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
