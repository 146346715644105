import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #fff;
  box-shadow: lightgrey 0px 2px 8px -3px;
  padding: 30px;
  border-radius: 10px;
  margin-top: 11px;
  width: 531px;

  img {
    margin-left: 10px;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    > div {
      margin-bottom: 20px;
    }
  }
`;


export const ProfilePicture = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .avatar {
    width: 140px;
    height: 140px;
    border: 3px solid var(--main);
    border-radius: 40px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 10px;
  }

  label {
    cursor: pointer;
    display: table;
    margin: 0 auto;

    &:hover {
      opacity: 0.7;
    }
    input {
      display: none;
    }
  }

  @media (max-width: 550px) {
    > div {
      margin-left: 20px;
    }
  }
`;

export const Form = styled.form`
  margin-left: 60px;

  div {
    width: 254px;
  }

  .password {
    cursor: pointer;
    margin-top: 10px;
  }

  > button {
    margin-top: 30px;
    width: 294px;
  }

  @media (max-width: 550px) {
    margin-left: 0px;

    div {
      width: 100%;
    }

    > button {
      width: 100%;
    }
  }
`;

export const SaveButton = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;

  button {
    width: 100%;
    max-width: 256px;
    font: 1.2rem 'FontsFreeNetSFProTextBold', sans-serif;
    border-radius: 28px;
  }
`;
