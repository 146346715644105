import styled from 'styled-components';

export const Container = styled.aside`
  > h1.hideMenu {
    display: none;
  }
`;

export const Content = styled.form`

`;

export const NavigationButton = styled.form`
  width: 50%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;

    > img {
      background: #94FFC1;
      padding: 20px 30px 20px 30px;
      border-radius: 20px;
    }

    > h2 {
      font: 1.4rem 'FontsFreeNetSFProTextMedium',sans-serif;
      color: #8E8E93;
      margin-left: 40px;
      font-weight: bold;
    }
  }

 &.hideMenu {
    display: none;
  }

  @media (max-width: 1130px) {
    width: 100%;
  }
`;
