import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import 'antd/dist/antd.css';
import AppProvider from './hooks/Index';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import { GlobalStyle } from './styles/global';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <ConfigProvider>
        <Routes />
        <ToastContainer autoClose={3000}></ToastContainer>
        <GlobalStyle />
      </ConfigProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
