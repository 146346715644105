import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Container } from './_ColumnChart';
import { LoadingOutlined } from '@ant-design/icons';

interface BillingsProps {
  input: Array<{
    month: string;
    value: string;
  }>;
  output: Array<{
    month: string;
    value: string;
  }>;
}

interface ColumnChartProps {
  billing: BillingsProps | undefined;
  loadingBilling: boolean | undefined;
}

export const ColumnChart = ({ billing, loadingBilling }: ColumnChartProps) => {
  const inputValues =
    billing?.input &&
    billing?.input.map((input: any) => {
      return parseInt(input.value);
    });

  const outputValues =
    billing?.output &&
    billing?.output.map((input: any) => {
      return parseInt(input.value);
    });

  const inputValuesArr: any = inputValues;
  const outputValuesArr: any = outputValues;

  const chartOptions: any = {
    colors: ['#21E270', '#FF2D55'],
    chart: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 7,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        billing?.input[0].month || 0,
        billing?.input[1].month || 0,
        billing?.input[2].month || 0,
        billing?.input[3].month || 0,
      ],
    },
    yaxis: {
      categories: [1, 2, 3, 4],
      tickAmount: 4,
      max: function (max: number) {
        if (inputValuesArr && outputValuesArr) {
          const inputMax = Math.max(...inputValuesArr);
          const outputMax = Math.max(...outputValuesArr);

          const maxValue = Math.max(inputMax, outputMax);
          max = maxValue;
          return max;
        }
      },
      min: function (min: number) {
        if (inputValuesArr && outputValuesArr) {
          const inputMin = Math.min(...inputValuesArr);
          const outputMin = Math.min(...outputValuesArr);

          const minValue = Math.min(inputMin, outputMin);
          min = minValue;
          return min;
        }
      },
      labels: {
        formatter: function (value: number) {
          if (value === 0) {
            return value;
          }

          return 'R$' + Math.round(value * 100) / 100;
        },
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
  };

  const chartSeries = [
    {
      name: 'Entradas',
      data: [
        billing?.input[0].value || 0,
        billing?.input[1].value || 0,
        billing?.input[2].value || 0,
        billing?.input[3].value || 0,
      ],
      colors: '#21E270',
    },
    {
      name: 'Saídas',
      data: [
        billing?.output[0].value || 0,
        billing?.output[1].value || 0,
        billing?.output[2].value || 0,
        billing?.output[3].value || 0,
      ],
    },
  ];

  return (
    <Container>
      {!loadingBilling ? (
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height="300"
        />
      ) : (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      )}
    </Container>
  );
};
