import { useState } from 'react';
import { MdMoneyOff } from 'react-icons/md';
import { FiEdit3 } from 'react-icons/fi';
import { BiTrash } from 'react-icons/bi';
import { EditModal } from './components/EditModal/EditModal';

import { CategoryWrapper, ActionButtons } from './_Category';

interface CategoriesInterface {
  category: {
    id: string;
    name: string;
    price: number;
    set_every_month: boolean;
  };
  deleteCategories: any;
  setLoadingEditCategories: (value: boolean) => void;
}

export const Category = ({
  category,
  deleteCategories,
  setLoadingEditCategories,
}: CategoriesInterface) => {
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <CategoryWrapper>
      <MdMoneyOff />
      <aside>
        <h3>{category.name}</h3>
        <h4>FIXO MENSAL</h4>
        <p>Valor: R$ {category.price}</p>
      </aside>

      <ActionButtons>
        <span>
          <BiTrash onClick={() => deleteCategories(category.id)} />
        </span>

        <span>
          <FiEdit3 onClick={() => setOpenEditModal(true)} />
        </span>
      </ActionButtons>
      <EditModal
        categoryId={category.id}
        categoryName={category.name}
        categoryPrice={category.price}
        categoryEveryMonth={category.set_every_month}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        setLoadingEditCategories={setLoadingEditCategories}
      />
    </CategoryWrapper>
  );
};
