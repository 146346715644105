import { Switch, BrowserRouter } from 'react-router-dom';
import { Route } from './Route';

import { Home } from '../pages/Home/Home';
import { OutcomesConfig } from '../pages/OutcomesConfig/OutcomesConfig';
import { Establishments } from '../pages/Establishments/Establishments';
import { Register } from '../pages/Register/Register';

import { SignIn } from '../pages/SignIn/SignIn';
import { Config } from '../pages/Config/Config';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route Component={SignIn} path="/" exact />
      <Route Component={Home} path="/dashboard" exact isPrivate />
      <Route
        Component={OutcomesConfig}
        path="/configurar-saidas"
        exact
        isPrivate
      />
      <Route
        Component={Establishments}
        path="/estabelecimentos"
        exact
        isPrivate
      />
      <Route Component={Register} path="/cadastros" exact isPrivate />

      <Route Component={Config} path="/configuracao" exact isPrivate />
    </Switch>
  </BrowserRouter>
);

export default Routes;
