export const getFormattedDate = (date: string) => {
  const newDate = new Date(date);
  const str =
    newDate.getFullYear() +
    '/' +
    (newDate.getMonth() + 1) +
    '/' +
    newDate.getDate();
  return str;
}
