import styled from 'styled-components';

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  nav {
    display: flex;
    flex-direction: column;
  }

  > Button {
    margin-top: 1.5rem;
  }
`;

export const NavigationButton = styled.button`
  background-color: var(--white);
  max-width: 260px;
  max-height: 55px;
  width: 260px;
  height: 55px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 28px;
  border: 0;
  color: var(--main);

  & + button {
    margin-top: 1.5rem;
  }

  &.active {
    font-family: 'FontsFreeNetSFProTextBold';
    background-color: var(--main);
    color: var(--white);
  }
`;
