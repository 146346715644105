import styled from 'styled-components';

export const CategoryWrapper = styled.div`
  width: 100%;
  max-width: 340px;
  background: #EFEFF4;
  padding: 1rem 0.8rem;
  border-radius: 8px;

  display: flex;
  align-items: center;
  position: relative;

  svg {
    font-size: 2.4rem;
    color: var(--main);
    margin-right: 12px;
  }

  aside {
    h3 {
      font: 0.8rem 'FontsFreeNetSFProTextMedium', sans-serif;
      color: #8E8E93;
      text-transform: uppercase;
    }

    h4 {
      font: 0.6rem 'FontsFreeNetSFProTextMedium', sans-serif;
      color: #8E8E93;
      text-transform: uppercase;
      margin: 2px 0;
    }

    p {
      font: 0.6rem 'FontsFreeNetSFProTextMedium', sans-serif;
      color: var(--main);
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 5px;

  span {
    border: 0;
    background: transparent;
    box-shadow: none !important;

    svg {
      font-size: 20px !important;
      cursor: pointer;
    }

    & + button {
      margin-left: 0.2rem;
    }

    &:first-child {
      svg {
        color: #FF2D55 !important;
      }
    }
  }
`;
