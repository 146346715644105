import React, { useState, useEffect } from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import { isBefore } from 'date-fns';
import { useAuth } from '../hooks/Auth';

interface RouteProps {
  isPrivateAndPublic?: boolean;
  isPrivate?: boolean;
  Component: React.FC;
  path: string;
  exact?: boolean;
}

export const Route = ({
  isPrivateAndPublic = false,
  isPrivate = false,
  Component,
  path,
  exact,
}: RouteProps) => {
  const { data, signOut, refreshToken } = useAuth();
  const [renderRoute, setRenderRoute] = useState(false);

  const verifyAccessToken = async () => {
    setRenderRoute(false);
    if (data.accessToken) {
      const decodedToken: any = decode(data.accessToken);

      const currentDate = new Date();
      let expirationDate = new Date(decodedToken.exp * 1000);
      if (isBefore(expirationDate, currentDate)) {
        if (data.saveLogin) {
          await refreshToken();
        } else {
          signOut();
          window.location.reload();
          return;
        }
      }

      setRenderRoute(true);
    } else {
      setRenderRoute(true);
    }
  };

  useEffect(() => {
    verifyAccessToken();
  }, [path]);

  return (
    <ReactDOMRoute
      exact
      render={({ location }) => {
        if (isPrivateAndPublic) {
          return renderRoute && <Component />;
        }

        if (
          isPrivate ===
          (data.accessToken !== undefined && data.accessToken !== '')
        ) {
          return renderRoute && <Component />;
        }

        return (
          renderRoute && (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/dashboard',
              }}
            />
          )
        );
      }}
    />
  );
};
