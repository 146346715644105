import Modal from 'react-modal';
import Calendar from 'react-calendar';
import { useState } from 'react';
import { format } from 'date-fns';

import { AiFillCheckCircle } from 'react-icons/ai';
import { ImRadioUnchecked } from 'react-icons/im';

import { CalendarContainer, CompleteMonthSelector } from './_ModalCalendar';

import ptBR from 'date-fns/locale/pt-BR';
import 'react-calendar/dist/Calendar.css';
import { Button } from '../Button/Button';

Modal.setAppElement('#root');

interface calendarProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
  setRevenues: (value: number) => void;
  setModalCalendar: any;
  setModalCalendarDate?: any;
  rangeData?: boolean;
  setRangeData?: any;
}

export const ModalCalendar = ({
  isModalOpen,
  setIsModalOpen,
  setRevenues,
  rangeData,
  setRangeData,
  setModalCalendarDate,
  setModalCalendar,
}: calendarProps) => {
  const [calendarValue, setCalendarValue] = useState([new Date(), new Date()]);
  const [completeMonth, setCompleteMonth] = useState(false);

  function handleDateSelect(value: Date[] | Date) {
    const SelectedDates = value as Date[];
    if (completeMonth) {
      return;
    }

    setCalendarValue(SelectedDates);
  }

  function handleCompleteMonthSelect() {
    setCompleteMonth(!completeMonth);

    if (completeMonth) {
      return;
    }

    setModalCalendarDate([new Date(), new Date()]);
  }

  function handleRangeSelect() {
    setRangeData(!rangeData);
  }

  function handleRevenue() {
    setRevenues(1);
    setIsModalOpen(!isModalOpen);
    setModalCalendarDate(calendarValue);
    setModalCalendar(true);
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => {
        setIsModalOpen(false);
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <CalendarContainer>
        <Calendar
          selectRange={rangeData}
          minDetail={'year'}
          onChange={(value) => {
            handleDateSelect(value);
          }}
          formatShortWeekday={(locale, date) =>
            format(date, 'iiiii', { locale: ptBR })
          }
          value={calendarValue}
          calendarType="US"
        />

        <section>
          <div>
            {/* <CompleteMonthSelector>
              <button onClick={handleCompleteMonthSelect}>
                {!completeMonth ? (
                  <ImRadioUnchecked style={{ color: '#C7C7CC' }} />
                ) : (
                  <AiFillCheckCircle style={{ color: '#21E270' }} />
                )}
              </button>

              <strong>Faturamento do mês completo</strong>
            </CompleteMonthSelector> */}

            <CompleteMonthSelector style={{ marginTop: '20px' }}>
              <button onClick={handleRangeSelect}>
                {!rangeData ? (
                  <ImRadioUnchecked style={{ color: '#C7C7CC' }} />
                ) : (
                  <AiFillCheckCircle style={{ color: '#21E270' }} />
                )}
              </button>

              <strong>Faturamento entre datas</strong>
            </CompleteMonthSelector>
          </div>

          <Button onClickFunction={handleRevenue}>ok</Button>
        </section>
      </CalendarContainer>
    </Modal>
  );
};
