import { DonutChart } from 'shared/components/DonutChart/DonutChart';
import { ColumnChart } from 'shared/components/ColumnChart/ColumnChart';
import { LineChart } from 'shared/components/LineChart/LineChart';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Container,
  Chart,
  BarChart,
  ChartInfo,
  ColoredIdentifier,
} from './_Dashboards';

interface ReportsProps {
  input: string;
  output: string;
}

interface BillingsProps {
  input: Array<{
    month: string;
    value: string;
  }>;
  output: Array<{
    month: string;
    value: string;
  }>;
}

interface DashboardsProps {
  revenues: number;
  reports: ReportsProps | undefined;
  billing: BillingsProps | undefined;
  loadingReports: boolean | undefined;
  loadingBilling: boolean | undefined;
  monthYearLabel: string;
}

export const Dashboards = ({
  revenues,
  reports,
  billing,
  loadingReports,
  loadingBilling,
  monthYearLabel,
}: DashboardsProps) => {
  return (
    <Container>
      <Chart>
        <strong>Faturamento</strong>
        <span>{monthYearLabel}</span>
        <DonutChart reports={reports} loadingReports={loadingReports} />
      </Chart>

      <ChartInfo>
        <div>
          {!loadingReports ? (
            <>
              <section>
                <ColoredIdentifier type="income" />
                Entradas: R$ {reports?.input}
              </section>

              <section>
                <ColoredIdentifier type="outcome" />
                Saídas: R$ {reports?.output}
              </section>
            </>
          ) : (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          )}
        </div>
      </ChartInfo>

      <BarChart>
        <strong>Histórico de faturamento</strong>

        {revenues === 0 ? (
          <ColumnChart billing={billing} loadingBilling={loadingBilling} />
        ) : (
          <>
            <span>{monthYearLabel}</span>
            <LineChart billing={billing} loadingBilling={loadingBilling} />
          </>
        )}
      </BarChart>
    </Container>
  );
};
