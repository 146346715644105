import React, { useEffect } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { Establishment } from '../../../hooks/Establishment';
import {
  CardContainer,
  CardHead,
  CardAddress,
  CardOptions,
  CardNotSolicitated,
  ContainerSpinner
} from './_EstablishmentCard';
import { Switch } from 'antd';
import { useState } from 'react';

import paymentIcon from 'shared/icons/paymentIcon.svg';
import { useEstablishment } from '../../../hooks/Establishment';

import { formatCurrencyBr } from '../../../utils/Masks';
import { Spinner } from 'shared/components/Spinner/Spinner';

interface establishmentDataProps extends Establishment {
  revenues?: number;
  commission?: number;
}

interface EstablishmentCardProps {
  establishmentData: establishmentDataProps;
}

export const EstablishmentCard: React.FC<EstablishmentCardProps> = ({
  establishmentData,
}) => {
  const { updatePaymentMahineSwitcher } = useEstablishment();
  const [cardMachine, setCardMachine] = useState<boolean>(
    establishmentData.presential_payment,
  );
  const [ loadingCardMachine, setLoadingCardMachine ] = useState<boolean>(false);

  console.log(establishmentData);

  useEffect(() => {
    setCardMachine(establishmentData.presential_payment);
  }, [establishmentData.presential_payment]);

  const handleChangeSwitch = (id: string, cardMachine: boolean) => {
    updatePaymentMahineSwitcher(id, cardMachine, setCardMachine, setLoadingCardMachine);
  };

  function renderPaymentMachineSwitch() {
    if(loadingCardMachine){
      return (
        <ContainerSpinner>
          <Spinner/>
        </ContainerSpinner>
      );
    }

    if (cardMachine) {
      return (
        <div>
          <div className={cardMachine ? 'active' : ''}>
            <img src={paymentIcon} alt="ícone payment" />
          </div>
          <Switch
            checked={cardMachine}
            onChange={(value) =>
              handleChangeSwitch(establishmentData.id, value)
            }
          />
        </div>
      );
    } /*else if (cardMachine === null) {
      return (
        <CardNotSolicitated>
          <img src={paymentIcon} alt="ícone payment" />
          Não solicitado
        </CardNotSolicitated>
      );
    }*/
    else if (cardMachine === null || !cardMachine) {
      return (
        <div> 
          <div className={cardMachine ? '' : ''}>
            <img src={paymentIcon} alt="ícone payment" />
          </div>

          <Switch
            checked={cardMachine}
            onChange={(value) =>
              handleChangeSwitch(establishmentData.id, value)
            }
          />
        </div>
      );
    }
  }

  return (
    <CardContainer key={establishmentData.id}>
      <CardHead>
        <div>
          <img src={establishmentData.image_url} alt="logo mcdonalds" />
        </div>

        <section>
          <span>{establishmentData.company_name}</span>
          <div>
            <AiFillStar
              className={parseInt(establishmentData.rating) > 0 ? 'active' : ''}
            />
            <AiFillStar
              className={parseInt(establishmentData.rating) > 1 ? 'active' : ''}
            />
            <AiFillStar
              className={parseInt(establishmentData.rating) > 2 ? 'active' : ''}
            />
            <AiFillStar
              className={parseInt(establishmentData.rating) > 3 ? 'active' : ''}
            />
            <AiFillStar
              className={parseInt(establishmentData.rating) > 4 ? 'active' : ''}
            />
          </div>
        </section>
      </CardHead>

      <CardAddress>
        {establishmentData.addresses?.city
          ? establishmentData.addresses?.city
          : '---'}
      </CardAddress>

      <td>{formatCurrencyBr(establishmentData?.revenues)}</td>
      <td>{formatCurrencyBr(establishmentData?.commission)}</td>

      <CardOptions>{renderPaymentMachineSwitch()}</CardOptions>
    </CardContainer>
  );
};
