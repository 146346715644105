import { FormEvent, useState } from 'react';
import Modal from 'react-modal';
import { AiFillCheckCircle } from 'react-icons/ai';
import { ImRadioUnchecked } from 'react-icons/im';
import { toast } from 'react-toastify';
import { Input } from '../../../../../shared/components/Input/Input';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../../hooks/Auth';
import api from '../../../../../services/api';

import exit from '../../../../../shared/icons/exit.png';

import { Title, Row, Form, SaveButton, AllMonthsSelector } from './_EditModal';

interface Props {
  categoryId: string;
  categoryName: string;
  categoryPrice: number;
  categoryEveryMonth: boolean;
  openEditModal: boolean;
  setOpenEditModal: (value: boolean) => void;
  setLoadingEditCategories: (value: boolean) => void;
}

export const EditModal: React.FC<Props> = ({
  categoryId,
  categoryName,
  categoryPrice,
  categoryEveryMonth,
  openEditModal,
  setOpenEditModal,
  setLoadingEditCategories,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '263px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };
  const { data } = useAuth();
  const [name, setName] = useState(categoryName);
  const [price, setPrice] = useState(categoryPrice);
  const [selectAllMonths, setSelectAllMonths] = useState(categoryEveryMonth);
  const [loading, setLoading] = useState(false);

  function handleAllMonthsSelector() {
    setSelectAllMonths(!selectAllMonths);
  }

  const validateFormFields = () => {
    if (!name.trim() || !price) {
      return false;
    }
    return true;
  };

  async function handleSubmit() {
    if (!validateFormFields()) {
      toast.error('Digite todos os campos!');
      return;
    }

    const usr = {
      name,
      price,
      setEveryMonth: selectAllMonths,
    };

    setLoadingEditCategories(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.put(`outputs/${categoryId}`, usr, { headers });

      setLoadingEditCategories(false);
      toast.success('Alterações salvas com sucesso!');
    } catch (err) {
      setLoadingEditCategories(false);
      handleError(err);
    }
  }

  const handleError = async (error: any) => {
    let message;
    if (error.response.data?.message) {
      message = error.response?.data.message;
    } else {
      message = error.toString();
    }

    setLoading(false);
    toast.error(message);
  };

  return (
    <Modal
      isOpen={openEditModal}
      onRequestClose={() => setOpenEditModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenEditModal(false)}
        />
      </Row>
      <Title>Editar Categoria</Title>
      Nome:
      <div>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
        />
      </div>
      Preço:
      <div>
        <Input
          value={price}
          onChange={(e: any) => setPrice(e.target.value)}
          type="number"
        />
      </div>
      <AllMonthsSelector>
        <span className="radioButton" onClick={handleAllMonthsSelector}>
          {!selectAllMonths ? (
            <ImRadioUnchecked style={{ color: '#C7C7CC' }} />
          ) : (
            <AiFillCheckCircle style={{ color: '#21E270' }} />
          )}
        </span>
        <p>Fixar valor em todos os meses</p>
      </AllMonthsSelector>
      <SaveButton>
        <div
          onClick={() => handleSubmit()}
          style={{ background: '#21E270', color: '#fff' }}
        >
          {loading ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            'Salvar alterações'
          )}
        </div>
      </SaveButton>
    </Modal>
  );
};
