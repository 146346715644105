import { useEffect, useState, FormEvent } from 'react';
import { FaPen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { WrapperPage } from '../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../shared/components/Input/Input';
import { Button } from '../../shared/components/Button/Button';
import { EditModal } from './components/EditModal/EditModal';
import { SuccessModal } from '../../shared/components/SuccessModal/SuccessModal';
import { useAuth } from '../../hooks/Auth';
import { LoadingOutlined } from '@ant-design/icons';
import api from '../../services/api';

import fallbackImg from '../../assets/config/fallbackImg.jpg';
import editIcon from '../../shared/icons/editIcon.png';

import { Container, ProfilePicture, Form, SaveButton } from './_Config';

export function Config() {
  const { data, setData } = useAuth();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState(data.user.first_name);
  const [lastName, setLastname] = useState(data.user.last_name);
  const [email, setEmail] = useState(data.user.email);
  const [userImg, setUserImg] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  useEffect(() => {
    setUserImg(data.user.avatar_url);
  }, []);

  const validateFormFields = () => {
    if (!name.trim() || !lastName.trim() || !email.trim()) {
      return false;
    }
    return true;
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!validateFormFields()) {
      toast.error('Digite todos os campos!');
      return;
    }

    const usr = {
      firstName: name,
      lastName: lastName,
      email: email,
    };

    setLoading(true);

    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const res = await api.put(`users/master`, usr, { headers });

      setData({
        accessToken: data.accessToken,
        user: res.data,
      });
      setIsModalOpen(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  }

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    setLoadingPhoto(true);

    if (fileList) {
      const formData = new FormData();
      formData.append('avatar', fileList[0]);

      try {
        const res = await api.patch(`users/${data.user.id}/avatar`, formData, {
          headers: {
            'Content-Type': `multipart/form-data;`,
            Authorization: `Bearer ${data.accessToken}`,
          },
        });

        setUserImg(res.data.avatar_url);
        setData({
          accessToken: data.accessToken,
          user: res.data,
        });
        setLoadingPhoto(false);
        toast.success('Foto de perfil alterada!');
      } catch (err) {
        setLoadingPhoto(false);
        handleError(err);
      }
    }
  };

  const handleError = async (error: any) => {
    let message;
    if (error.response.data?.message) {
      message = error.response?.data.message;
    } else {
      message = error.toString();
    }

    setLoading(false);
    toast.error(message);
  };

  return (
    <WrapperPage title="Configurações">
      <h2 style={{ fontSize: '20px' }}>Alterar dados</h2>

      <Container>
        <ProfilePicture>
          <img
            src={userImg || fallbackImg}
            className="avatar"
            alt="Imagem de perfil"
            onError={(e: any) => {
              e.target.src = fallbackImg;
            }}
          />
          <label htmlFor="avatar">
            <span>
              {loadingPhoto ? (
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              ) : (
                <div>
                  <FaPen color="#21e270" /> alterar foto
                </div>
              )}
            </span>
            <input
              id="avatar"
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </label>
        </ProfilePicture>

        <Form onSubmit={handleSubmit}>
          Nome:
          <div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              iconRight={editIcon}
            />
          </div>
          Sobrenome:
          <div>
            <Input
              value={lastName}
              onChange={(e) => setLastname(e.target.value)}
              iconRight={editIcon}
            />
          </div>
          Email:
          <div>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              iconRight={editIcon}
              type="email"
            />
          </div>
          Senha:
          <div className="password">
            <span onClick={() => setOpenEditModal(true)}>
              <FaPen color="#21e270" /> alterar senha
            </span>
          </div>
          <SaveButton>
            <Button style={{ background: '#21E270', color: '#fff' }}>
              {loading ? (
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              ) : (
                'Salvar alterações'
              )}
            </Button>
          </SaveButton>
        </Form>
        <EditModal
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          setOpenModal={setIsModalOpen}
        />
        <SuccessModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          text="Alterações salvas com sucesso!"
        />
      </Container>
    </WrapperPage>
  );
}
