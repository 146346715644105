import { FormEvent, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Input } from '../../../../../../shared/components/Input/Input';
import { Button } from '../../../../../../shared/components/Button/Button';
import { LoadingOutlined } from '@ant-design/icons';
import { useCategories } from '../../../../../../hooks/Categories';

import exit from '../../../../../../shared/icons/exit.png';

import { Title, Row, Form, SaveButton } from './_EditModal';

interface Props {
  openEditModal: boolean;
  setOpenEditModal: (value: boolean) => void;
}

export const EditModal: React.FC<Props> = ({
  openEditModal,
  setOpenEditModal,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '263px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };
  const { updateCategoriesMenu, loading } = useCategories();
  const [categoryName, setCategoryName] = useState('');

  const validateFormFields = () => {
    if (!categoryName.trim()) {
      return false;
    }
    return true;
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!validateFormFields()) {
      toast.error('Não é permitido campo vazio!');
      return;
    }

    updateCategoriesMenu(categoryName);
    setCategoryName('');
  }

  return (
    <Modal
      isOpen={openEditModal}
      onRequestClose={() => setOpenEditModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenEditModal(false)}
        />
      </Row>

      <Form onSubmit={handleSubmit}>
        <Title>Editar Categoria</Title>
        Nome:
        <div>
          <Input
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            type="text"
          />
        </div>
        <SaveButton>
          <Button style={{ background: '#21E270', color: '#fff' }}>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Salvar alterações'
            )}
          </Button>
        </SaveButton>
      </Form>
    </Modal>
  );
};
