import { useEffect, useState, FormEvent } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { ImRadioUnchecked } from 'react-icons/im';
import { FiEdit3 } from 'react-icons/fi';
import { Button } from 'shared/components/Button/Button';
import { WrapperPage } from 'shared/components/WrapperPage/WrapperPage';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/Auth';

import { Category } from './Category/Category';
import { ContentHeader } from 'shared/components/ContentHeader/ContentHeader';
import { SuccessModal } from 'shared/components/SuccessModal/SuccessModal';

import api from '../../services/api';

import {
  ContentBox,
  Content,
  Input,
  AllMonthsSelector,
  NumberInput,
  CategoryItems,
  SaveButton,
  Form,
} from './_OutcomesConfig';

interface CategoriesInterface {
  id: string;
  name: string;
  price: number;
  set_every_month: boolean;
}

export const OutcomesConfig = () => {
  const { data } = useAuth();

  const [selectAllMonths, setSelectAllMonths] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState();
  const [categories, setCategories] = useState<CategoriesInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingDeleteCategories, setLoadingDeleteCategories] = useState(false);
  const [loadingEditCategories, setLoadingEditCategories] = useState(false);

  function handleAllMonthsSelector() {
    setSelectAllMonths(!selectAllMonths);
  }

  const validateFormFields = () => {
    if (!name.trim() || !price) {
      return false;
    }
    return true;
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!validateFormFields()) {
      toast.error('Digite todos os campos!');
      return;
    }

    const usr = {
      name,
      price,
      setEveryMonth: selectAllMonths,
    };

    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.post(`outputs`, usr, { headers });

      setIsModalOpen(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  }

  async function getCategories() {
    if (!loadingDeleteCategories) {
      setLoadingCategories(true);
      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };
        const res = await api.get(`outputs`, { headers });

        setCategories(res.data);
        setLoadingCategories(false);
      } catch (err) {
        setLoadingCategories(false);
        handleError(err);
      }
    }
  }

  async function deleteCategories(id: string) {
    setLoadingDeleteCategories(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.delete(`/outputs/${id}`, { headers });
      setLoadingDeleteCategories(false);
      toast.error('Categoria deletada!');
    } catch (err) {
      setLoadingDeleteCategories(false);
      handleError(err);
    }
  }

  const handleError = async (error: any) => {
    let message;
    if (error.response.data?.message) {
      message = error.response?.data.message;
    } else {
      message = error.toString();
    }

    setLoading(false);
    toast.error(message);
  };

  useEffect(() => {
    getCategories();
  }, [loading, loadingEditCategories, loadingDeleteCategories]);

  return (
    <WrapperPage title="Dashboard">
      <Form onSubmit={handleSubmit}>
        <ContentHeader title="Configurar Saídas" />

        <Content>
          <ContentBox>
            <h2>Inserir Categorias</h2>
            <div>
              <span>Nome da categoria:</span>
              <div>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Digite o nome.."
                  type="text"
                />
              </div>
            </div>

            <h2>Valor</h2>
            <NumberInput>
              <Input
                value={price}
                onChange={(e: any) => setPrice(e.target.value)}
                placeholder="R$ 0,00"
                type="number"
              />
              <FiEdit3 />
            </NumberInput>

            <AllMonthsSelector>
              <span className="radioButton" onClick={handleAllMonthsSelector}>
                {!selectAllMonths ? (
                  <ImRadioUnchecked style={{ color: '#C7C7CC' }} />
                ) : (
                  <AiFillCheckCircle style={{ color: '#21E270' }} />
                )}
              </span>
              <p>Fixar valor em todos os meses</p>
            </AllMonthsSelector>
          </ContentBox>

          <ContentBox>
            <h2>Categorias Cadastradas</h2>

            <CategoryItems>
              {!loadingCategories ? (
                <>
                  {categories.map((category) => (
                    <Category
                      category={category}
                      deleteCategories={deleteCategories}
                      setLoadingEditCategories={setLoadingEditCategories}
                    />
                  ))}
                </>
              ) : (
                <div className="loadingCategories">
                  <LoadingOutlined
                    style={{ fontSize: 24, color: '#21e270' }}
                    spin
                  />
                </div>
              )}
            </CategoryItems>
          </ContentBox>
        </Content>

        <SaveButton>
          {!loading ? (
            <Button>Salvar</Button>
          ) : (
            <Button>
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </Button>
          )}
        </SaveButton>
        <SuccessModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          text="Categoria adicionada com sucesso!"
        />
      </Form>
    </WrapperPage>
  );
};
