import styled from 'styled-components';

export const ModalContent = styled.div`
  color: var(--main);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.2rem 0;

  p {
    max-width: 200px;
    font: 1.2rem 'FontsFreeNetSFProTextBold', sans-serif;
    text-align: center;
    margin-bottom: 2rem;
  }

  svg {
    font-size: 10rem;
  }
`;

export const Text = styled.div`
`;

export const Row = styled.div`
`;
