import { Select as SelecteAntd, SelectProps } from 'antd';
import { Container } from './_SelectCategorie';

interface Props extends SelectProps<string> {
  data: Categories[];
  placeholder?: string | undefined;
  defaultValue?: string | undefined;
}

interface Categories {
  id: string;
  name: string;
  created_at: string;
}

export const SelectCategorie = ({
  data,
  placeholder,
  defaultValue,
  ...rest
}: Props) => {
  const { Option } = SelecteAntd;

  const renderOptions = () => {
    return data.map((element, index) => (
      <Option value={element.id} key={index}>
        {element.name}
      </Option>
    ));
  };

  return (
    <Container>
      <SelecteAntd
        listHeight={1000}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...rest}
      >
        {renderOptions()}
      </SelecteAntd>
    </Container>
  );
};
