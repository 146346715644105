import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  >div {
    width: 14.8rem;
    margin-right: 1.5rem;

    @media (max-width: 450px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .calendarIcon {
    width: 40px;
    padding: 10px;
    box-shadow: 0px 2px 6px #00000030;
    border-radius: 10px;
    cursor: pointer;

    > svg {
      display: table;
      margin: 0 auto;
    }
  }

  input[type="search"] {
    width: 16.25rem;
    height: 50px;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 2px 6px #00000030;
    padding: 0 0 0 1.2rem;
    font-family: 'FontsFreeNetSFProTextLight';
    margin-right: 1.5rem;

    &::placeholder {
      color: #D0CFD0;
    }

    @media (max-width: 450px) {
      width: 100%;
      margin-right: 0;
    }
  }

  button {
    font-size: 1.8rem;

    @media (max-width: 450px) {
      width: 100%;
      height: 40px;
      border-radius: 40px;
      margin: 0.5rem 0 1.5rem;
    }
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
