import { useState, HTMLAttributes } from 'react';

import { SideBar } from '../SideBar/SideBar';
import { Header } from '../Header/Header';
import { Content } from '../Content/Content';

import { Container, Column } from './_WrapperPage';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

export const WrapperPage = ({ title, children }: Props) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [showMenu, setShowMenu] = useState(true);

  return (
    <Container>
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} showMenu={showMenu}/>
      <Column>
        <Header
          title={title}
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Content>{children}</Content>
      </Column>
    </Container>
  );
};
