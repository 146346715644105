import { Dispatch, HTMLAttributes, SetStateAction } from 'react';
import { Content } from '../../components/Content/Content';

import menu from '../../icons/menu-green.png';

import { Background, Container, Title, MenuIcon } from './_Header';

interface Props extends HTMLAttributes<HTMLHeadElement> {
  title: string;
  showSideBar: boolean;
  setShowSideBar: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({
  title,
  showSideBar,
  setShowSideBar,
}: Props) => {
  const renderIconMenu = () => {
    if (!showSideBar) {
      return (
        <MenuIcon
          onClick={() => setShowSideBar(true)}
          src={menu}
          alt="Ícone do menu"
        />
      );
    }
  };

  return (
    <Background>
      <Container>
        <Content>
          <Title>
            {renderIconMenu()}
            {title}
          </Title>
        </Content>
      </Container>
    </Background>
  );
};
