import { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Button } from 'shared/components/Button/Button';
import { Select } from './Select/Select';
import { BsFillCircleFill } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';

import { SelectCategorie } from './SelectCategorie/SelectCategorie';

import {
  Container,
  Content,
  ContentWrapper,
  SendConfig,
  Input,
  ContainerRange,
  Selector,
  SelectorsWrapper,
  Subtitle,
} from './_Notifications';
import { useEstablishment } from '../../../hooks/Establishment';

import api from 'services/api';
import { useAuth } from 'hooks/Auth';
import { toast } from 'react-toastify';

interface Categories {
  id: string;
  name: string;
  created_at: string;
}

export const Notifications = () => {
  const [selectedRange, setSelectedRange] = useState(1);
  const [notificationInfo, setNotificationInfo] = useState({
    title: '',
    content: '',
    categoryId: '',
    radius: selectedRange,
  });
  const [categoriesServices, setCategoriesServices] = useState<Categories[]>(
    [] as Categories[],
  );

  const { data } = useAuth();
  const { searchEstablishments, establishment, handleNotificationSend } =
    useEstablishment();

  useEffect(() => {
    searchEstablishments();
    getCategories();
  }, []);

  function handleEstablishmentChoice(e: any) {}

  function handleRadiusValue(value: number) {
    setSelectedRange(value);
    setNotificationInfo({ ...notificationInfo, radius: value });
  }

  function handleTitleNotification(e: any) {
    setNotificationInfo({ ...notificationInfo, title: e.target.value });
  }

  function handleContentNotification(e: any) {
    setNotificationInfo({ ...notificationInfo, content: e.target.value });
  }

  function selectCategories(e: any) {
    setNotificationInfo({ ...notificationInfo, categoryId: e });
  }

  async function getCategories() {
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const response = await api.get('/categories/establishments?limit=3', {
        headers,
      });
      setCategoriesServices(response.data.results);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  }

  return (
    <Container>
      <h1>Notificações</h1>

      <ContentWrapper>
        <Content>
          <h2>Título da Notificação</h2>
          <input
            placeholder="Escreva o título aqui.."
            type="text"
            onChange={(e) => handleTitleNotification(e)}
          />

          <h2>Descrição</h2>
          <textarea
            placeholder="Escreva a descrição aqui.."
            onChange={(e) => handleContentNotification(e)}
          ></textarea>
        </Content>

        <SendConfig>
          <h2>Envio</h2>

          <span>Público:</span>

          <SelectCategorie
            data={categoriesServices}
            onChange={(e) => selectCategories(e)}
            placeholder="Pesquisar"
          ></SelectCategorie>

          <span>Estabelecimento:</span>

          <Select
            data={establishment}
            onChange={(e) => handleEstablishmentChoice(e)}
            placeholder="Pesquisar"
          ></Select>

          <span>Raio de Alcance:</span>
          <ContainerRange>
            <SelectorsWrapper>
              <Selector
                className={selectedRange > 0 ? 'active' : ''}
                onClick={() => {
                  handleRadiusValue(1);
                }}
              >
                {selectedRange === 1 ? (
                  <FaCheckCircle />
                ) : (
                  <BsFillCircleFill
                    onClick={() => {
                      handleRadiusValue(1);
                    }}
                  />
                )}
              </Selector>

              <Selector
                className={selectedRange > 1 ? 'active' : ''}
                onClick={() => {
                  handleRadiusValue(2);
                }}
              >
                <hr />
                {selectedRange === 2 ? (
                  <FaCheckCircle />
                ) : (
                  <BsFillCircleFill
                    onClick={() => {
                      handleRadiusValue(2);
                    }}
                  />
                )}
              </Selector>

              <Selector
                className={selectedRange > 2 ? 'active' : ''}
                onClick={() => {
                  handleRadiusValue(3);
                }}
              >
                <hr />
                {selectedRange === 3 ? (
                  <FaCheckCircle />
                ) : (
                  <BsFillCircleFill
                    onClick={() => {
                      handleRadiusValue(3);
                    }}
                  />
                )}
              </Selector>

              <Selector
                className={selectedRange > 3 ? 'active' : ''}
                onClick={() => {
                  handleRadiusValue(4);
                }}
              >
                <hr />
                {selectedRange === 4 ? (
                  <FaCheckCircle />
                ) : (
                  <BsFillCircleFill
                    onClick={() => {
                      handleRadiusValue(4);
                    }}
                  />
                )}
              </Selector>

              <Selector
                className={selectedRange > 4 ? 'active' : ''}
                onClick={() => {
                  handleRadiusValue(5);
                }}
              >
                <hr />
                {selectedRange === 5 ? (
                  <FaCheckCircle />
                ) : (
                  <BsFillCircleFill
                    onClick={() => {
                      handleRadiusValue(5);
                    }}
                  />
                )}
              </Selector>
            </SelectorsWrapper>

            <Subtitle>
              <span>01 Km</span>
              <span>02 Km</span>
              <span>03 Km</span>
              <span>04 Km</span>
              <span>05 Km</span>
            </Subtitle>
          </ContainerRange>

          <Button
            onClickFunction={() => handleNotificationSend(notificationInfo)}
          >
            Criar notificação
          </Button>
        </SendConfig>
      </ContentWrapper>
    </Container>
  );
};
