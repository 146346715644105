import React, {
  createContext,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';

import { toast } from 'react-toastify';

import api from '../services/api';
import { useAuth } from '../hooks/Auth';

interface CategoriesContextData {
  categoriesId: string;
  setCategoriesId: Dispatch<SetStateAction<string>>;
  updateCategoriesServices: (categoryName: string) => Promise<void>;
  updateCategoriesMenu: (categoryName: string) => Promise<void>;
  insertCategoriesServices: (categoryName: string) => Promise<void>;
  insertCategoriesMenu: (categoryName: string) => Promise<void>;
  deleteCategory: (categoryId: string) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  openEditModal: boolean;
  setOpenEditModal: (value: boolean) => void;
  pageServices: number;
  pageMenu: number;
  totalEstablishments: number;
  totalMenu: number;
  toggleInput: boolean;
  setToggleInput: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loadingBtn: boolean;
  setPageServices: Dispatch<SetStateAction<number>>;
  setTotalServices: Dispatch<SetStateAction<number>>;
  setPageMenu: Dispatch<SetStateAction<number>>;
  setTotalMenu: Dispatch<SetStateAction<number>>;
}

const CategoriesContext = createContext<CategoriesContextData>(
  {} as CategoriesContextData,
);

const CategoriesProvider: React.FC = ({ children }) => {
  const { data } = useAuth();
  const [categoriesId, setCategoriesId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [pageServices, setPageServices] = useState(1);
  const [pageMenu, setPageMenu] = useState(1);
  const [totalEstablishments, setTotalServices] = useState(10);
  const [totalMenu, setTotalMenu] = useState(10);
  const [toggleInput, setToggleInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const updateCategoriesServices = async (categoryName: string) => {
    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.put(
        `/categories/${categoriesId}/establishments`,
        { name: categoryName },
        {
          headers,
        },
      );
      setOpenEditModal(!openEditModal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const updateCategoriesMenu = async (categoryName: string) => {
    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.put(
        `/categories/${categoriesId}/menu`,
        { name: categoryName },
        {
          headers,
        },
      );
      setOpenEditModal(!openEditModal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const insertCategoriesServices = async (categoryName: string) => {
    setLoadingBtn(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.post(
        `/categories/establishments`,
        { name: categoryName },
        {
          headers,
        },
      );
      setLoadingBtn(false);
      toast.success('Categoria inserida!');
    } catch (error) {
      setLoadingBtn(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const insertCategoriesMenu = async (categoryName: string) => {
    setLoadingBtn(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.post(
        `/categories/menu`,
        { name: categoryName },
        {
          headers,
        },
      );
      setLoadingBtn(false);
      toast.success('Categoria inserida!');
    } catch (error) {
      setLoadingBtn(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const deleteCategory = async (categoryId: string) => {
    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.delete(`/categories/${categoryId}`, {
        headers,
      });
      toast.success('Categoria removida!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  return (
    <CategoriesContext.Provider
      value={{
        categoriesId,
        setCategoriesId,
        insertCategoriesMenu,
        insertCategoriesServices,
        updateCategoriesServices,
        updateCategoriesMenu,
        deleteCategory,
        isModalOpen,
        setIsModalOpen,
        openEditModal,
        setOpenEditModal,
        pageServices,
        pageMenu,
        totalEstablishments,
        totalMenu,
        toggleInput,
        setToggleInput,
        loading,
        setLoading,
        loadingBtn,
        setPageServices,
        setTotalServices,
        setPageMenu,
        setTotalMenu,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

function useCategories(): CategoriesContextData {
  const context = useContext(CategoriesContext);

  if (!context) {
    throw new Error('useCategories must be used within an CategoriesProvider');
  }

  return context;
}

export { CategoriesProvider, useCategories };
