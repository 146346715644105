import Modal from 'react-modal';
import { HiCheckCircle } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
import exit from '../../icons/exit.png';

import { ModalContent } from './_SuccessModal';

Modal.setAppElement('#root');

interface calendarProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  text?: string;
}

const customModalStyle = {
  content: {
    maxWidth: '312px',
    maxHeight: '320px',
    width: '91%',
  },
};

export const SuccessModal = ({
  isModalOpen,
  setIsModalOpen,
  text,
}: calendarProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => {
        setIsModalOpen(false);
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      style={customModalStyle}
    >
      <ModalContent>
        <p>{text}</p>
        <HiCheckCircle />
      </ModalContent>

      <img
        src={exit}
        alt="Ícone de sair"
        onClick={() => setIsModalOpen(false)}
        className="react-modal-close"
      />
    </Modal>
  );
};
