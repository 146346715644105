import { useEffect } from 'react';
import { Select } from 'shared/components/Select/Select';
import { Container } from './_Outcomes';
import { useHistory, useLocation } from 'react-router-dom';

import { FiSearch, FiCalendar } from 'react-icons/fi';
import { Button } from 'shared/components/Button/Button';
import { ModalCalendar } from 'shared/components/ModalCalendar/ModalCalendar';
import { useState } from 'react';

import { useEstablishment } from '../../../../hooks/Establishment';

interface OutcomesProps {
  setRevenues?: (value: number) => void;
  setModalCalendarDate?: any;
  rangeData?: boolean;
  setRangeData?: any;
  setModalCalendar?: any;
  setMonthFilter?: any;
  setMonth?: any;
  SelectOptions: string[];
  isEstablishment: boolean;
  placeholder: string;
}

export const Outcomes = ({
  setRevenues,
  setMonthFilter,
  setMonth,
  SelectOptions,
  isEstablishment,
  placeholder,
  setModalCalendarDate,
  rangeData,
  setRangeData,
  setModalCalendar,
}: OutcomesProps) => {
  const {
    establishmentSearch,
    setEstablishmentSearch,
    searchEstablishments,
    fetchEstablishment,
    changeFilterMachine,
    searchEstablishmentsByCity,
    setEstablishmentSearchByCity,
    establishmentSearchByCity,
  } = useEstablishment();
  const history = useHistory();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleSearch() {
    if (history.location.pathname === '/dashboard') {
      history.push('/estabelecimentos');
    }
    searchEstablishments();
  }

  function handleSearchByCity() {
    console.log('oi');
    if (history.location.pathname === '/dashboard') {
      history.push('/estabelecimentos');
    }
    searchEstablishmentsByCity();
  }

  function handleChange(value: string) {
    if (value === 'Decrescente' || value === 'Crescente') {
      fetchEstablishment(value, true, false);
    } else {
      changeFilterMachine(value, true, false);
    }

    if (location.pathname !== '/estabelecimentos') {
      setMonth(value);
      setModalCalendar(false);

      if (value === 'Janeiro') {
        setMonthFilter(1);
      } else if (value === 'Fevereiro') {
        setMonthFilter(2);
      } else if (value === 'Março') {
        setMonthFilter(3);
      } else if (value === 'Abril') {
        setMonthFilter(4);
      } else if (value === 'Maio') {
        setMonthFilter(5);
      } else if (value === 'Junho') {
        setMonthFilter(6);
      } else if (value === 'Julho') {
        setMonthFilter(7);
      } else if (value === 'Agosto') {
        setMonthFilter(8);
      } else if (value === 'Setembro') {
        setMonthFilter(9);
      } else if (value === 'Outubro') {
        setMonthFilter(10);
      } else if (value === 'Novembro') {
        setMonthFilter(11);
      } else if (value === 'Dezembro') {
        setMonthFilter(12);
      }
    }
  }

  return (
    <>
      <Container>
        {location.pathname !== '/estabelecimentos' ? (
          <div className="calendarIcon" onClick={() => setIsModalOpen(true)}>
            <FiCalendar color="grey" size="20px" />
          </div>
        ) : (
          ''
        )}
        <Select
          onChange={handleChange}
          onClick={() => {
            // setModalCalendarDate(undefined);
          }}
          data={SelectOptions}
          placeholder={placeholder}
        ></Select>
        <input
          value={establishmentSearch}
          onChange={(e) => setEstablishmentSearch(e.target.value)}
          placeholder="Pesquissar estabelecimento.."
          type="search"
        />
        <input
          value={establishmentSearchByCity}
          onChange={(e) => setEstablishmentSearchByCity(e.target.value)}
          placeholder="Pesquisar por cidade.."
          type="search"
        />
        <Button
          onClickFunction={() => {
            if (establishmentSearch.length > 0) {
              handleSearch();
            } else if (establishmentSearchByCity.length > 0) {
              handleSearchByCity();
            }
          }}
        >
          <FiSearch />
        </Button>
      </Container>

      {!isEstablishment && setRevenues && (
        <ModalCalendar
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setRevenues={setRevenues}
          setModalCalendarDate={setModalCalendarDate}
          setModalCalendar={setModalCalendar}
          rangeData={rangeData}
          setRangeData={setRangeData}
        />
      )}
    </>
  );
};
