import { useState } from 'react';
import { Menus } from './Menus/Menus';
import { Services } from './Services/Services';
import { Container, NavigationButton } from './_Categories';

import forkKnife from '../../../shared/icons/forkKnife.png';

interface CategoriesProps {}

export const Categories = () => {
  const [activeTab, setActiveTab] = useState('');

  return (
    <Container>
      <h1
        className={
          activeTab === 'services' || activeTab === 'menus' ? 'hideMenu' : ''
        }
      >
        Selecione a Categoria
      </h1>

      <aside>
        {activeTab === 'services' ? (
          <Services setActiveTab={setActiveTab} />
        ) : (
          <></>
        )}
        {activeTab === 'menus' ? <Menus setActiveTab={setActiveTab} /> : <></>}
      </aside>

      <nav>
        <NavigationButton
          onClick={() => {
            setActiveTab('services');
          }}
          className={
            activeTab === 'services' || activeTab === 'menus' ? 'hideMenu' : ''
          }
        >
          <div>
            <img src={forkKnife} />
            <h2>Serviços</h2>
          </div>
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setActiveTab('menus');
          }}
          className={
            activeTab === 'services' || activeTab === 'menus' ? 'hideMenu' : ''
          }
        >
          <div>
            <img src={forkKnife} />
            <h2>Cardápios</h2>
          </div>
        </NavigationButton>
      </nav>
    </Container>
  );
};
