import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    font-size: 3rem;
    color: var(--main);
    margin-left: -18px;
  }

  h1 {
    font: 1.8rem 'FontsFreeNetSFProTextMedium', sans-serif;
    color: #8E8E93;
  }

  @media (max-width: 1020px) {
    justify-content: center;
  }
`;
