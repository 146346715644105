import React from 'react';

import { AuthProvider } from './Auth';
import { PrivacyPolicyProvider } from './PrivacyPolicy';
import { EstablishmentProvider } from './Establishment';
import { CategoriesProvider } from './Categories';
import { TaxesComissionsProvider } from './TaxesComissions';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <PrivacyPolicyProvider>
      <EstablishmentProvider>
        <CategoriesProvider>
          <TaxesComissionsProvider>{children}</TaxesComissionsProvider>
        </CategoriesProvider>
      </EstablishmentProvider>
    </PrivacyPolicyProvider>
  </AuthProvider>
);

export default AppProvider;
