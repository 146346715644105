import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { FaChevronLeft, FaPen, FaTrash } from 'react-icons/fa';
import { Container, CategoryBox, CategoryList } from './_Menus';
import { Input } from '../../../../shared/components/Input/Input';
import correctIcon from '../../../../shared/icons/correctIcon.png';
import forkKnife from '../../../../shared/icons/forkKnife.png';
import { SuccessModal } from 'shared/components/SuccessModal/SuccessModal';
import { EditModal } from './components/EditModal/EditModal';
import { LoadingOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';

import { useCategories } from '../../../../hooks/Categories';
import { getFormattedDate } from '../../../../utils/Validations';

interface ServicePorps {
  setActiveTab: Dispatch<SetStateAction<string>>;
}

interface Categories {
  id: string;
  name: string;
  created_at: string;
}

export const Menus = ({ setActiveTab }: ServicePorps) => {
  const { data } = useAuth();
  const {
    pageMenu,
    totalMenu,
    setCategoriesId,
    deleteCategory,
    openEditModal,
    setOpenEditModal,
    insertCategoriesMenu,
    loading,
    loadingBtn,
    setPageMenu,
    setTotalMenu,
  } = useCategories();
  const [categoryname, setCategoryName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoriesMenu, setCategoriesMenu] = useState<Categories[]>([]);

  async function getCategories() {
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const resMenu = await api.get('/categories/menu?limit=3', {
        headers,
      });
      setCategoriesMenu(resMenu.data.results);
      setPageMenu(resMenu.data.page);
      setTotalMenu(resMenu.data.total);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  }

  useEffect(() => {
    getCategories();
  }, [openEditModal, loading, loadingBtn]);

  async function handleChangePageMenu(page: number) {
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const resMenu = await api.get(`/categories/menu?page=${page}&limit=3`, {
        headers,
      });
      setCategoriesMenu(resMenu.data.results);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  }

  function renderCategoryList() {
    return categoriesMenu.map((category) => (
      <CategoryList key={category.id}>
        <img src={forkKnife} />
        <div>
          <h2>{category.name}</h2>
          <p>CARDPÁPIO</p>
          <p>Data do Cadastro: {getFormattedDate(category.created_at)}</p>
        </div>
        {HandleCategoryActions(category.id)}
      </CategoryList>
    ));
  }

  function HandleCategoryActions(id: string) {
    return (
      <>
        <FaTrash className="actionIcons" onClick={() => deleteCategory(id)} />
        <FaPen
          className="actionIcons"
          onClick={() => {
            setOpenEditModal(true);
            setCategoriesId(id);
          }}
        />
      </>
    );
  }

  function handleInsertCategory() {
    if (categoryname.trim() === '') {
      toast.error('Digite o nome da categoria!');
      return;
    }

    insertCategoriesMenu(categoryname);
    setCategoryName('');
  }

  return (
    <Container>
      <div
        onClick={() => {
          setActiveTab('');
        }}
      >
        <FaChevronLeft size={21} color="#21e270" />
        <h1>Categoria de Cardápios</h1>
      </div>

      <CategoryBox>
        <div>
          <h1>Inserir Categoria</h1>

          <h5>Nome da categoria:</h5>
          <div>
            <Input
              value={categoryname}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Digite o nome..."
            />
            <button
              onClick={() => {
                handleInsertCategory();
              }}
            >
              {!loadingBtn ? (
                <img src={correctIcon} />
              ) : (
                <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />
              )}
            </button>
          </div>

          <h1>Categorias Cadastradas</h1>
          {renderCategoryList()}
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              defaultCurrent={pageMenu}
              total={40}
              onChange={(e) => handleChangePageMenu(e)}
            />
          </span>
        </div>
      </CategoryBox>
      <EditModal
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
      />
      <SuccessModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        text="Alterações salvas com sucesso!"
      />
    </Container>
  );
};
