import styled from 'styled-components';

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  height: 371px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 31px;
  padding: 2.5rem;

  > div {
    display: flex;
  }

  h2 {
    font: 1.4rem 'FontsFreeNetSFProTextLight', sans-serif;
    color: var(--main);
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-top: auto;
    }
  }

  @media(max-width: 1020px) {
    > div {
      flex-direction: column;
    }
    height: fit-content;
  }

  @media(max-width: 320px) {
    width: 240px;
    align-items: center;
  }
`;

export const InputWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  div {
    span {
      display: block;
      margin-bottom: 10px;
    }

    input {
      max-width: 126px;
      height: 50px;
      border: none;
      box-shadow: 0px 2px 6px #00000030;
      border-radius: 30px;
      padding: 11px 3rem 11px 1rem;
    }

    .ant-select {
      max-width: 126px;
      margin: 0;
    }
  }

  @media(max-width: 1020px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

export const Input = styled.div`
  position: relative;
  max-width: 126px;

  svg, span {
    position: absolute;
    right: 16px;
    top: 12px;
    font-size: 1.5rem;
    color: var(--main);
  }
`;
