import styled from 'styled-components';

export const Container = styled.aside`
  > div {
    display: flex;
    align-items: baseline;

    > h1 {
      margin-left: 8px;
    }

    > svg {
      cursor: pointer;
    }
  }

`;

export const CategoryBox = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 20px 10px 20px 20px;

    h1 {
      font-size: 20px;
      color: #21e270;
    }

    > div {
      display: flex;
      align-items: center;

      button {
        background: #21e270;
        border-radius: 10px;
        border: 0;
        padding: 10px;
        margin-left: 20px;
      }
    }
  }
`;

export const CategoryList = styled.div`
  display: flex;
  background: #f3f2f2;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;

  > img {
    margin-right: 10px;
  }

  > div {
    > p {
      margin: 8px 0 5px 0;
      word-break: break-word;
    }

    p:last-child {
      font-size: 14px;
      color: var(--main);
      font-weight: bold;
    }
  }

  > svg {
    margin-left: 10px;
    color: red;
    cursor: pointer;
    margin-top: 30px;
    font-size: 22px;
  }

  svg:last-child {
    color: var(--main);
    cursor: pointer;
    margin-top: 30px;
  }
`;

