import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

export const Column = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  border-bottom-left-radius: 55px;
  border-top-left-radius: 55px;

    @media (max-width: 1024px) {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;

      min-height: 100vh;

      > div {
      padding-bottom: 100px;
    }
  }
`;
