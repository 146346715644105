import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const EstablishmentsWrapper = styled.main`
  width: 100%;
  box-shadow: 0px 2px 6px #00000040;
  border-radius: 52px 52px 0 0;
  height: 100%;

  padding: 2.8rem 2rem;
  margin-top: 3rem;

  display: flex;
  flex-direction: column;

  > button {
    background: transparent;
    font: 1.5rem 'FontsFreeNetSFProTextLight', sans-serif;
    color: #C7C7CC;
    border: none;
    padding: 2rem 0;
    align-self: center;
  }

  @media (max-width: 1024px) {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #E9E9E9;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--main);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--main);
    }
  }
`;

export const Table = styled.table`
  width: 100%;

  th {
    color: var(--main);
    background: #FAFAFA;
    padding: 1rem 2rem;
    text-align: center;
    font: 1.2rem 'FontsFreeNetSFProTextMedium', sans-serif;
  }

  td {
    padding: 1rem 0.5rem;
    border: 0;
    text-align: center;
    vertical-align: middle;
    justify-content: start;
  }
`;

export const ShowMore = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  color: var(--main);
  margin: 20px auto;
  text-align: center;
  cursor: pointer;
`;
