import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  background: var(--main);
  width: 60px;
  height: 50px;
  border: none;
  border-radius: 15px;
  color: #FFFFFF;
`;
