import styled from 'styled-components';

export const Title = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.2rem;
  color: var(--main);
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;

  > img {
    cursor: pointer;
  }
`;

export const Form = styled.form`
  ${Title} {
    margin-bottom: 10px;
  }

  div {
    width: 100%;
  }

  .password {
    margin-top: 10px;
  }

  > button {
    margin-top: 30px;
    width: 100%;
  }
`;

export const SaveButton = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;

  button {
    width: 100%;
    max-width: 256px;
    font: 1.2rem 'FontsFreeNetSFProTextBold', sans-serif;
    border-radius: 28px;
  }
`;
