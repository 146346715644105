import { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import loginGirl from 'assets/signIn/loginGirl.svg';
import user from 'assets/signIn/user.svg';
import lock from 'assets/signIn/lock.svg';
import logo from 'shared/icons/logo@2x.png';
import { useAuth } from '../../hooks/Auth';

import { Container, Form } from './styles';

export const SignIn = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveLogin, setSaveLogin] = useState(false);

  const validateFormFields = () => {
    if (!email.trim() || !password.trim()) {
      return false;
    }
    return true;
  };

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!validateFormFields()) {
      toast.error('Digite corretamente seu e-mail e senha');
      return;
    }

    try {
      setLoading(true);

      await signIn({
        email,
        password,
        saveLogin,
      });

      setLoading(false);
      history.push('/dashboard');
      toast.success('Seja bem vindo!');
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  }

  const handleError = async (error: any) => {
    let message;
    if (error.response.data?.message) {
      message = error.response?.data.message;
    } else {
      message = error.toString();
    }

    setLoading(false);
    toast.error(message);
  };

  return (
    <Container>
      <main>
        <section>
          <img src={logo} alt="Payment logo" />
          <h1>Bem-vindo(a) de volta!</h1>

          <span>Acesse sua conta:</span>
        </section>

        <Form onSubmit={handleSubmit}>
          <input
            name="email"
            placeholder="E-mail"
            type="text"
            className="signInInput"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ backgroundImage: `url(${user})` }}
          />
          <input
            name="password"
            placeholder="Senha"
            type="password"
            className="signInInput"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ backgroundImage: `url(${lock})` }}
          />

          <div>
            <span>
              <input
                type="checkbox"
                checked={saveLogin}
                onChange={() => setSaveLogin(!saveLogin)}
              />
              Salvar login
            </span>

            <a href="/">Esqueci a senha</a>
          </div>

          <button type="submit">
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Entrar'
            )}
          </button>
        </Form>
      </main>

      <aside>
        <img src={loginGirl} alt="Garota usando cartão" />
      </aside>
    </Container>
  );
};
