import Chart from 'react-apexcharts';
import { Container } from './_LineChart';

interface BillingsProps {
  input: Array<{
    month: string;
    value: string;
  }>;
  output: Array<{
    month: string;
    value: string;
  }>;
}

interface LineChartProps {
  billing: BillingsProps | undefined;
  loadingBilling: boolean | undefined;
}

export const LineChart = ({ billing, loadingBilling }: LineChartProps) => {
  const inputValues =
    billing?.input &&
    billing?.input.map((input: any) => {
      return parseInt(input.value);
    });

  const outputValues =
    billing?.output &&
    billing?.output.map((input: any) => {
      return parseInt(input.value);
    });

  const inputValuesArr: any = inputValues;
  const outputValuesArr: any = outputValues;

  const chartOptions: any = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#21E270', '#FF2D55'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: '',
      align: 'left',
    },
    markers: {
      size: 7,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0,
      },
    },
    xaxis: {
      categories: ['1ª', '2ª', '3ª', '4ª'],
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          if (value === 0) {
            return value;
          }

          return 'R$' + Math.round(value * 100) / 100;
        },
      },
      max: function (max: number) {
        if (inputValuesArr && outputValuesArr) {
          const inputMax = Math.max(...inputValuesArr);
          const outputMax = Math.max(...outputValuesArr);

          const maxValue = Math.max(inputMax, outputMax);
          max = maxValue;
          return max;
        }
      },
      min: function (min: number) {
        if (inputValuesArr && outputValuesArr) {
          const inputMin = Math.min(...inputValuesArr);
          const outputMin = Math.min(...outputValuesArr);

          const minValue = Math.min(inputMin, outputMin);
          min = minValue;
          return min;
        }
      },
    },
    legend: {
      show: false,
    },
  };

  const chartSeries = [
    {
      name: 'Entradas',
      data: [
        billing?.input[0].value,
        billing?.input[1].value,
        billing?.input[2].value,
        billing?.input[3].value,
      ],
    },
    {
      name: 'Saídas',
      data: [
        billing?.output[0].value,
        billing?.output[1].value,
        billing?.output[2].value,
        billing?.output[3].value,
      ],
    },
  ];

  return (
    <Container>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={300}
      />
    </Container>
  );
};
