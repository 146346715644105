import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';

import api from '../services/api';

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string;
}

interface AuthState {
  accessToken: string;
  refreshToken?: string;
  user: User;
  saveLogin?: boolean;
}

interface SignInCredentials {
  email: string;
  password: string;
  saveLogin: boolean;
}

interface AuthContextData {
  data: AuthState;
  setData: Dispatch<SetStateAction<AuthState>>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  refreshToken(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const saveLogin =
      !!localStorage.getItem('@Payment-adm:accessToken') || false;

    let accessToken;
    let refreshToken;
    let user;
    if (saveLogin) {
      accessToken = localStorage.getItem('@Payment-adm:accessToken') || '';
      refreshToken = localStorage.getItem('@Payment-adm:refreshToken') || '';
      user = localStorage.getItem('@Payment-adm:user') || '{}';
    } else {
      accessToken = sessionStorage.getItem('@Payment-adm:accessToken') || '';
      refreshToken = sessionStorage.getItem('@Payment-adm:refreshToken') || '';
      user = sessionStorage.getItem('@Payment-adm:user') || '{}';
    }

    if (accessToken) {
      return {
        accessToken,
        refreshToken,
        user: JSON.parse(user),
        saveLogin,
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password, saveLogin }: SignInCredentials) => {
      const response = await api.post('sessions/master', {
        email,
        password,
      });

      const { user } = response.data;
      const accessToken = response.data.accessToken;
      const refreshToken = response.data.refreshToken;

      if (saveLogin) {
        setData({ accessToken, refreshToken, user, saveLogin });
      } else {
        setData({ accessToken, user, saveLogin });
      }
    },
    [],
  );

  const updateLocalStorage = () => {
    if (JSON.stringify(data) === '{}') {
      localStorage.removeItem('@Payment-adm:accessToken');
      localStorage.removeItem('@Payment-adm:refreshToken');
      localStorage.removeItem('@Payment-adm:user');
      localStorage.removeItem('@Payment-adm:saveLogin');
      sessionStorage.removeItem('@Payment-adm:accessToken');
      sessionStorage.removeItem('@Payment-adm:refreshToken');
      sessionStorage.removeItem('@Payment-adm:user');
      return;
    }

    if (data.saveLogin) {
      localStorage.setItem('@Payment-adm:accessToken', data.accessToken || '');
      localStorage.setItem(
        '@Payment-adm:refreshToken',
        data.refreshToken || '',
      );
      localStorage.setItem(
        '@Payment-adm:user',
        JSON.stringify(data.user) || '',
      );
      localStorage.setItem('@Payment-adm:saveLogin', data.saveLogin.toString());
    } else {
      sessionStorage.setItem(
        '@Payment-adm:accessToken',
        data.accessToken || '',
      );
      sessionStorage.setItem(
        '@Payment-adm:user',
        JSON.stringify(data.user) || '',
      );
      localStorage.setItem('@Payment-adm:saveLogin', 'false');
    }
  };

  const signOut = useCallback(() => {
    setData({} as AuthState);
  }, []);

  const refreshToken = async () => {
    return new Promise((resolve, reject) => {
      try {
        const refreshToken = data.refreshToken;
        api
          .put('/sessions/refresh-tokens', {
            refreshToken,
          })
          .then((res: any) => {
            setData({
              ...data,
              refreshToken: res.data.refreshToken,
              accessToken: res.data.accessToken,
            });
            window.location.reload();
            return res;
          })
          .catch((err: any) => {
            signOut();
            window.location.reload();
            return err;
          });
      } catch (err) {
        return err;
      }
    });
  };

  useEffect(() => {
    updateLocalStorage();
  }, [data]);

  return (
    <AuthContext.Provider
      value={{ data, setData, signIn, signOut, refreshToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('userAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
