import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 4rem;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    max-width: 510px;
  }
`;

export const Chart = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 28px;
  padding: 19px 0;
  text-align: center;

  strong {
    font: 1.5rem 'FontsFreeNetSFProTextSemibold1', sans-serif;
    color: #8E8E93;
    margin-bottom: 4px;
  }

  span {
    font: 1rem 'FontsFreeNetSFProTextLight', sans-serif;
    color: #C7C7CC;
    margin-bottom: 30px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 510px;
  }
`;

export const ChartInfo = styled(Chart)`
  display: flex;
  align-items: center;
  max-width: 290px;
  padding: 21px 0;
  text-align: left;
  margin-left: -2.5rem;
  z-index: 1;
  background-color: #EFEFF4;

  div {
    span {
      display: table;
      margin: 0 auto;
    }

    section {
      display: flex;
      align-items: center;
      font: 1.1rem 'FontsFreeNetSFProTextLight', sans-serif;
      color: #8E8E93;

      & + section {
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 510px;
    margin-left: 0;
  }
`;

interface ColoredIdentifierProps {
  type: 'income' | 'outcome';
}

export const ColoredIdentifier = styled(Chart)<ColoredIdentifierProps>`
  background: ${(props) =>
    props.type === 'income' ? '#21E270' : '#FF2D55'
  };

  width: 18px;
  height: 32px;
  border-radius: 4px;
  margin-right: 9px;
  box-shadow: none;
`;

export const BarChart = styled(Chart)`
  margin-left: auto;
  max-width: 25rem;
  padding: 21px;

  strong {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  tspan {
    font: 'FontsFreeNetSFProTextLight', sans-serif;
    color: #C7C7CC;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 510px;
    margin-left: 0;
  }
`;
