import { Link } from 'react-router-dom';
import establishment from 'shared/icons/establishment.svg';
import { Container } from './_ActiveEstablishments';

export const ActiveEstablishments = () => {
  return (
    <Container>
      <div>
        <div>
          <img src={establishment} alt="ícone de estabelecimento"/>
        </div>
        <strong>Estabelecimentos Ativos</strong>
      </div>

      <Link to="/estabelecimentos">
        <button>
          Ver detalhes
        </button>
      </Link>
    </Container>
  );
};
