import { useState } from 'react';
import { WrapperPage } from 'shared/components/WrapperPage/WrapperPage';
import { Navigation } from './Navigation/Navigation';
import { Notifications } from './Notifications/Notifications';
import { Categories } from './Categories/Categories';
import { Privacity } from './Privacity/Privacity';
import { Taxes } from './Taxes/Taxes';

import { Container } from './_Register';

export const Register = () => {
  const [activeTab, setActiveTab] = useState('privacity');

  return (
    <WrapperPage title="Cadastros">
      <Container>
        <aside>
          {activeTab === 'privacity' ? <Privacity /> : <></>}
          {activeTab === 'taxes' ? <Taxes /> : <></>}
          {activeTab === 'notifications' ? <Notifications /> : <></>}
          {activeTab === 'categories' ? <Categories /> : <></>}
        </aside>

        <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
      </Container>
    </WrapperPage>
  );
};
