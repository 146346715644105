import { useEffect } from 'react';
import { WrapperPage } from 'shared/components/WrapperPage/WrapperPage';
import { ContentHeader } from 'shared/components/ContentHeader/ContentHeader';
import { Outcomes } from 'pages/Home/components/Outcomes/Outcomes';

import {
  Content,
  EstablishmentsWrapper,
  Table,
  ShowMore,
} from './_Establishments';
import { EstablishmentCard } from './EstablishmentCard/EstablishmentCard';
import { Spinner } from '../../shared/components/Spinner/Spinner';

import { useEstablishment } from '../../hooks/Establishment';

export const Establishments = () => {
  const {
    establishment,
    loading,
    fetchEstablishment,
    changeFilterMachine,
    filterAlphabetic,
    filterMachineApi,
    filterMachineState,
    total,
  } = useEstablishment();

  useEffect(() => {
    fetchEstablishment(filterAlphabetic, true, false);
  }, []);

  const SelectOptions = [
    'Crescente',
    'Decrescente',
    'Maquininha Payment',
    'Maquininha Própria',
  ];

  const handleLoadMore = () => {
    if (!filterMachineApi) {
      fetchEstablishment(filterAlphabetic, false, true);
    } else {
      changeFilterMachine(filterMachineState, false, true);
    }
  };

  const renderShowMoreSolicitations = () => {
    if (loading) {
      return (
        <div style={{ marginTop: '10px' }}>
          <Spinner />
        </div>
      );
    }

    if (establishment.length < total) {
      return (
        <ShowMore
          onClick={() => {
            handleLoadMore();
          }}
        >
          Carregar mais solicitações
        </ShowMore>
      );
    }
  };

  return (
    <>
      <WrapperPage title="Dashboard">
        <Content>
          <ContentHeader title="Estabelecimentos" />
          <Outcomes
            isEstablishment={true}
            SelectOptions={SelectOptions}
            placeholder="Crescente"
          />
        </Content>

        <EstablishmentsWrapper>
          <Table>
            <thead>
              <tr>
                <th>Estabelecimento</th>
                <th>Localização</th>
                <th>Faturamento</th>
                <th>Comissão</th>
                <th>Maquininha</th>
              </tr>
            </thead>
            <tbody>
              {establishment.map((est) => (
                <EstablishmentCard establishmentData={est} />
              ))}
            </tbody>
          </Table>
          <>{renderShowMoreSolicitations()}</>
        </EstablishmentsWrapper>
      </WrapperPage>
    </>
  );
};
