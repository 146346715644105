import { HTMLAttributes } from 'react';

import { ButtonWrapper } from './_Button';

interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  onClickFunction?: () => void;
}

export const Button = ({ children, onClickFunction }: ButtonProps) => {
  return (
    <ButtonWrapper onClick={onClickFunction}>
      {children}
    </ButtonWrapper>
  );
};
