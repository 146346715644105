import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Container } from './_ContentHeader';

interface ContentHeaderProps {
  title: string;
}

export const ContentHeader = ({ title }: ContentHeaderProps) => {
  return (
    <Container>
      <Link to="/">
        <MdKeyboardArrowLeft />
      </Link>
      <h1>{title}</h1>
    </Container>
  );
};
