import { Dispatch, SetStateAction, FormEvent, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Input } from '../../../../shared/components/Input/Input';
import { Button } from '../../../../shared/components/Button/Button';
import { useAuth } from '../../../../hooks/Auth';
import api from '../../../../services/api';
import { LoadingOutlined } from '@ant-design/icons';

import exit from '../../../../shared/icons/exit.png';

import { Title, Row, Form, SaveButton } from './_EditModal';

interface Props {
  openEditModal: boolean;
  setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const EditModal: React.FC<Props> = ({
  openEditModal,
  setOpenEditModal,
  setOpenModal,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: '510px',
      width: '263px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };
  const { data, setData } = useAuth();

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  const validateFormFields = () => {
    if (
      !oldPassword.trim() ||
      !password.trim() ||
      !passwordConfirmation.trim()
    ) {
      return false;
    }
    return true;
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!validateFormFields()) {
      toast.error('Digite todos os campos!');
      return;
    }

    if (password.trim() !== passwordConfirmation.trim()) {
      toast.error('Os campos Nova Senha e Confirmar Senha são diferentes!');
      return;
    }

    const usr = {
      oldPassword,
      password,
      passwordConfirmation,
    };

    try {
      setLoading(true);

      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const res = await api.put(`users/master`, usr, { headers });
      setData({
        accessToken: data.accessToken,
        user: res.data,
      });
      setOpenEditModal(false);
      setOpenModal(true);
      setLoading(false);
      setOldPassword('');
      setPassword('');
      setPasswordConfirmation('');
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  }

  const handleError = async (error: any) => {
    let message;
    if (error.response.data?.message) {
      message = error.response?.data.message;
    } else {
      message = error.toString();
    }

    setLoading(false);
    toast.error(message);
  };

  return (
    <Modal
      isOpen={openEditModal}
      onRequestClose={() => setOpenEditModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenEditModal(false)}
        />
      </Row>

      <Form onSubmit={handleSubmit}>
        <Title>Alterar senha</Title>
        Senha Atual:
        <div>
          <Input
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            type="password"
          />
        </div>
        Nova Senha:
        <div>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>
        Confirmar Senha:
        <div>
          <Input
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            type="password"
          />
        </div>
        <SaveButton>
          <Button style={{ background: '#21E270', color: '#fff' }}>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Salvar alterações'
            )}
          </Button>
        </SaveButton>
      </Form>
    </Modal>
  );
};
