import React, {
  createContext,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';

import { toast } from 'react-toastify';

import api from '../services/api';
import { useAuth } from '../hooks/Auth';

interface PrivacityContextData {
  privacyPolicy: string;
  setPrivacyPolicy: Dispatch<SetStateAction<string>>;
  updatePrivacyPolicy: () => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  loading: boolean;
}

const PrivacyPoliceContext = createContext<PrivacityContextData>(
  {} as PrivacityContextData,
);

const PrivacyPolicyProvider: React.FC = ({ children }) => {
  const { data } = useAuth();
  const [privacyPolicy, setPrivacyPolicy] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getPrivacePolicy() {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const res = await api.get('/privacy-policy', { headers });

      setPrivacyPolicy(res.data.content);
    }

    getPrivacePolicy();
  }, []);

  const updatePrivacyPolicy = async () => {
    if (privacyPolicy.trim() === '') {
      toast.warning('O campo de texto não pode ser vazio!');
      return;
    }

    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.put(
        '/privacy-policy',
        { content: privacyPolicy },
        {
          headers,
        },
      );
      setIsModalOpen(!isModalOpen);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  return (
    <PrivacyPoliceContext.Provider
      value={{
        privacyPolicy,
        setPrivacyPolicy,
        updatePrivacyPolicy,
        isModalOpen,
        setIsModalOpen,
        loading,
      }}
    >
      {children}
    </PrivacyPoliceContext.Provider>
  );
};

function usePrivacyPolicy(): PrivacityContextData {
  const context = useContext(PrivacyPoliceContext);

  if (!context) {
    throw new Error(
      'usePrivacyPolicy must be used within an PrivacyPolicyProvider',
    );
  }

  return context;
}

export { PrivacyPolicyProvider, usePrivacyPolicy };
