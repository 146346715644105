import { SuccessModal } from 'shared/components/SuccessModal/SuccessModal';
import { Container, NavigationButton } from './_Navigation';
import { useAuth } from '../../../hooks/Auth';
import { useTaxesCommissions } from '../../../hooks/TaxesComissions';

import { LoadingOutlined } from '@ant-design/icons';

import { usePrivacyPolicy } from '../../../hooks/PrivacyPolicy';

interface NavigationProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const Navigation = ({ activeTab, setActiveTab }: NavigationProps) => {
  const { updatePrivacyPolicy, isModalOpen, setIsModalOpen, loading } =
    usePrivacyPolicy();
  const { isModaTaxeslOpen, setIsModaTaxeslOpen, updateVariables } =
    useTaxesCommissions();

  function submitAlterations() {
    updateVariables();
    updatePrivacyPolicy();
  }

  return (
    <Container>
      <nav>
        <NavigationButton
          onClick={() => {
            setActiveTab('privacity');
          }}
          className={activeTab === 'privacity' ? 'active' : ''}
        >
          Política de Privacidade
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setActiveTab('taxes');
          }}
          className={activeTab === 'taxes' ? 'active' : ''}
        >
          Taxas e Comissões
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setActiveTab('notifications');
          }}
          className={activeTab === 'notifications' ? 'active' : ''}
        >
          Notificações
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setActiveTab('categories');
          }}
          className={activeTab === 'categories' ? 'active' : ''}
        >
          Categorias
        </NavigationButton>
      </nav>

      {activeTab != 'categories' && activeTab != 'notifications' ? (
        <NavigationButton className="active" onClick={submitAlterations}>
          {!loading ? (
            activeTab === 'privacity' ? (
              'Editar termos'
            ) : activeTab === 'taxes' ? (
              'Salvar alterações'
            ) : activeTab === 'notifications' ? (
              'Criar notificação'
            ) : (
              ''
            )
          ) : (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          )}
        </NavigationButton>
      ) : (
        ''
      )}

      <SuccessModal
        isModalOpen={isModalOpen || isModaTaxeslOpen}
        setIsModalOpen={setIsModalOpen || setIsModaTaxeslOpen}
        text="Alterações salvas com sucesso!"
      />
    </Container>
  );
};
