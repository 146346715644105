import styled from 'styled-components';

export const Container = styled.aside`
  display: flex;
  flex-direction: column;

  div {
    max-width: 600px;
    height: 26.5rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 31px;
    padding: 2rem 1rem;

    textarea {
      width: 100%;
      height: 100%;
      border: none;
      text-align: left;
      color: #8E8E93;
      font: 1.4rem 'FontsFreeNetSFProTextLight', sans-serif;
      resize: none;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #E9E9E9;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--main);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--main);
      }
    }
  }
`;
