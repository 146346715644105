import styled from 'styled-components';

export const Content = styled.main`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  margin-top: 44px;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentBox = styled.aside`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 31px;
  padding: 1.8rem 2.25rem 2.8rem;

  max-height: 372px;

  h2 {
    color: var(--main);
    font: 1.4rem 'FontsFreeNetSFProTextMedium1', sans-serif;
    margin-bottom: 0.8rem;
    line-height: 30px;
  }

  div {
    div {
      display: flex;
      margin-top: 10px;

      input {
        max-width: 257px;
        margin-right: 1.4rem;
      }

      button {
        padding-top: 5px;
        max-width: 47px;
        max-height: 47px;
        font-size: 1.5rem;
        box-shadow: 0px 3px 6px #00000029;
      }
    }

    p {
      color: #8E8E93;
      font: 1rem 'FontsFreeNetSFProTextLight', sans-serif;
    }
  }
`;

export const Input = styled.input`
  background: var(--white);
  box-shadow: 0px 2px 6px #00000030;
  border-radius: 30px;
  border: 0;
  height: 47px;
  width: 100%;
  padding-left: 1.2rem;
  margin-bottom: 26px;

  &::placeholder {
    color: #D1D1D6;
    font: 1rem 'FontsFreeNetSFProTextLight', sans-serif;
  }
`;

export const NumberInput = styled.div`
  position: relative;
  max-width: 171px;

  svg {
    position: absolute;
    right: 16px;
    top: 12px;
    font-size: 1.5rem;
    color: var(--main);
  }
`;

export const AllMonthsSelector = styled.div`
  display: flex;
  align-items: center;

  strong {
    font: 1.2rem 'FontsFreeNetSFProTextBold', sans-serif;
  }

  span {
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    margin-right: 0.5rem;
    border: 0;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Form = styled.form`

`;

export const CategoryItems = styled.div`
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;

  .loadingCategories {
    display: table;
    margin: 100px auto;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #E9E9E9;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--main);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--main);
  }
`;

export const SaveButton = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;

  button {
    width: 100%;
    max-width: 256px;
    font: 1.2rem 'FontsFreeNetSFProTextBold', sans-serif;
    border-radius: 28px;
  }
`;
