import { Select as SelecteAntd, SelectProps } from 'antd';
import { Container } from './_Select';

interface Props extends SelectProps<string> {
  data: SearchProps[];
  placeholder?: string | undefined;
  defaultValue?: string | undefined;
}

interface SearchProps {
  id: string;
  company_name: string;
  presential_payment: boolean;
  rating: string;
  image_url: string;
  addresses: {
    id: string;
    street: string;
    number: string;
    cep: string;
    uf: string;
    city: string;
  };
}

export const Select = ({ data, placeholder, defaultValue, ...rest }: Props) => {
  const { Option } = SelecteAntd;

  const renderOptions = () => {
    return data.map((element, index) => (
      <Option value={element.id} key={index}>
        {element.company_name}
      </Option>
    ));
  };

  return (
    <Container>
      <SelecteAntd
        listHeight={1000}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...rest}
      >
        {renderOptions()}
      </SelecteAntd>
    </Container>
  );
};
