import Chart from 'react-apexcharts';
import { LoadingOutlined } from '@ant-design/icons';

interface ReportsProps {
  input: string;
  output: string;
}

interface DonutChartprops {
  reports: ReportsProps | undefined;
  loadingReports: boolean | undefined;
}

export const DonutChart = ({ reports, loadingReports }: DonutChartprops) => {
  const chartOptions: any = {
    labels: ['Entrada', 'Saída'],
    colors: ['#21E270', '#FF2D55'],
    dataLabels: {
      enabled: false,
    },
    chart: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    pie: {
      donut: {
        labels: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
  };

  const input = (reports?.input ? parseInt(reports?.input) : 0) || 0;
  const output = (reports?.output ? parseInt(reports?.output) : 0) || 0;

  const chartSeries = [input, output];

  return (
    <>
      {!loadingReports ? (
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          height="180"
        />
      ) : (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      )}
    </>
  );
};
