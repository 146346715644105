import { ChangeEvent } from 'react';
import { Container } from './_Privacity';

import { usePrivacyPolicy } from '../../../hooks/PrivacyPolicy';

export const Privacity = () => {
  const { privacyPolicy, setPrivacyPolicy } = usePrivacyPolicy();

  return (
    <Container>
      <h1>Termos de Uso e Política de Privacidade</h1>

      <div>
        <textarea
          value={privacyPolicy}
          onChange={(ev) => setPrivacyPolicy(ev.target.value)}
        />
      </div>
    </Container>
  );
};
