import styled, { css } from 'styled-components';

interface ContainerProps {
  showSideBar: boolean;
  showMenu: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 100vh;
  background-color: var(--main);
  transition: all 1s;
  margin-left: 0px;
  padding: 4.3rem 0;

  ${
    props => props.showSideBar === false && (
      css`
        margin-left: -160px;
      `
    )
  }

  @media (max-width: 1024px) {
    z-index: 2;
    position: fixed;
    bottom: 0;
    flex-direction: row;
    width: 100%;
    height: 80px;
    margin-left: 0px;
    padding: 2.3rem 0;
  }
`;

export const MenuIcon = styled.img`
  width: 27px;
  height: 20px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 280px;
  justify-content: space-around;

  > img {
    margin: 10px 0px;
    cursor: pointer;
  }

  .active {
    filter: brightness(0.3);
    opacity: 0.3;
  }

  > img:nth-of-type(3) {
    width: 40px;
  }

  @media (max-width: 1024px) {
   min-height: auto;
   flex-direction: row;
   justify-content: space-around;
   width: 100%;
  }
`;

export const Logo = styled.img`
  width: 70px;
  height: 53px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: none;
  }
`;
