import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';

import api from '../services/api';

import { useAuth } from './Auth';

interface TaxesComissionsContextData {
  transactions: string | undefined;
  cancellation: string | undefined;
  tolerance: string | undefined;
  rental: string | undefined;
  setTransactions: Dispatch<SetStateAction<string>>;
  setCancellation: Dispatch<SetStateAction<string>>;
  setTolerance: Dispatch<SetStateAction<string>>;
  setRental: Dispatch<SetStateAction<string>>;
  updateVariables: () => Promise<void>;
  isModaTaxeslOpen: boolean;
  setIsModaTaxeslOpen: (value: boolean) => void;
}

const TaxesComissionsContext = createContext<TaxesComissionsContextData>(
  {} as TaxesComissionsContextData,
);

const TaxesComissionsProvider: React.FC = ({ children }) => {
  const [transactions, setTransactions] = useState<string>('');
  const [cancellation, setCancellation] = useState<string>('');
  const [tolerance, setTolerance] = useState<string>('');
  const [rental, setRental] = useState<string>('');
  const { data } = useAuth();

  const [isModaTaxeslOpen, setIsModaTaxeslOpen] = useState(false);

  const updateVariables = async () => {
    let transactionsNumber = parseInt(transactions);
    let cancellationNumber = parseInt(cancellation);
    let rentalNumber = parseInt(rental);

    const parseNum = (tolerance: string) => {
      let toleranceString = tolerance.toString();
      toleranceString.replace(/[^.\d]/g, '');

      return parseInt(toleranceString);
    };

    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      api.put(
        '/variables',
        {
          canceledTransaction: cancellationNumber,
          minimumPeriod: parseNum(tolerance),
          paymentMachineRental: rentalNumber,
          transaction: transactionsNumber,
        },
        { headers },
      );
      toast.success('Alterações realizadas com sucesso!');
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  return (
    <TaxesComissionsContext.Provider
      value={{
        transactions,
        cancellation,
        tolerance,
        rental,
        setTransactions,
        setTolerance,
        setCancellation,
        setRental,
        updateVariables,
        isModaTaxeslOpen,
        setIsModaTaxeslOpen,
      }}
    >
      {children}
    </TaxesComissionsContext.Provider>
  );
};

function useTaxesCommissions(): TaxesComissionsContextData {
  const context = useContext(TaxesComissionsContext);

  if (!context) {
    throw new Error('userAuth must be used within an TaxesComissionsProvider');
  }

  return context;
}

export { TaxesComissionsProvider, useTaxesCommissions };
