import React, {
  createContext,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react';

import { toast } from 'react-toastify';

import api from '../services/api';
import { useAuth } from '../hooks/Auth';

export interface Establishment {
  id: string;
  company_name: string;
  presential_payment: boolean;
  rating: string;
  image_url: string;
  addresses: {
    id: string;
    street: string;
    number: string;
    cep: string;
    uf: string;
    city: string;
  };
}

interface EstablishmentContextData {
  establishment: Establishment[];
  setEstablishment: Dispatch<SetStateAction<Establishment[]>>;
  establishmentSearch: string;
  setEstablishmentSearch: Dispatch<SetStateAction<string>>;
  setEstablishmentSearchByCity: Dispatch<SetStateAction<string>>;
  searchEstablishmentsByCity: () => void;
  searchEstablishments: () => void;
  establishmentSearchByCity: string;
  isModalOpen: boolean;
  setFilterAlphabetic: Dispatch<SetStateAction<string>>;
  filterMachineApi: boolean | undefined;
  setFilterMachineApi: Dispatch<SetStateAction<boolean>>;
  filterAlphabeticApi: boolean | undefined;
  filterAlphabetic: string;
  filterMachineState: string;
  setFilterMachineState: Dispatch<SetStateAction<string>>;
  setFilterAlphabeticApi: Dispatch<SetStateAction<boolean>>;
  setIsModalOpen: (value: boolean) => void;
  fetchEstablishment: (
    value: string,
    resetPage: boolean,
    addPage: boolean,
  ) => void;
  changeFilterMachine: (
    value: string,
    resetPage: boolean,
    addPage: boolean,
  ) => void;
  updatePaymentMahineSwitcher: (
    id: string,
    cardMachine: boolean,
    setCardMachine: Dispatch<SetStateAction<boolean>>,
    setLoadingCardMachine: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>;
  loading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  handleNotificationSend: (notificationItems: any) => Promise<void>;
  notificationsInfos: any;
  setNotificationsInfos: any;
}

const EstablishmentContext = createContext<EstablishmentContextData>(
  {} as EstablishmentContextData,
);

const EstablishmentProvider: React.FC = ({ children }) => {
  const { data } = useAuth();
  const [establishment, setEstablishment] = useState<Establishment[]>([]);
  const [filterAlphabetic, setFilterAlphabetic] = useState<string>('ASC');
  const [filterAlphabeticApi, setFilterAlphabeticApi] =
    useState<boolean>(false);
  const [filterMachineState, setFilterMachineState] = useState<string>('');
  const [filterMachineApi, setFilterMachineApi] = useState<boolean>(false);
  const [establishmentSearch, setEstablishmentSearch] = useState<string>('');
  const [establishmentSearchByCity, setEstablishmentSearchByCity] =
    useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationsInfos, setNotificationsInfos] = useState();

  const fetchEstablishment = async (
    value: string,
    resetPage = false,
    addPage = false,
  ) => {
    let alphabeticFilter = 'ASC';

    if (value === 'Decrescente' || value === 'DESC') {
      alphabeticFilter = 'DESC';
      setFilterAlphabetic('DESC');
    } else {
      alphabeticFilter = 'ASC';
      setFilterAlphabetic('ASC');
    }

    let pageIndex = page;

    if (resetPage) {
      pageIndex = 1;
      setPage(1);
    }

    if (addPage) {
      pageIndex = pageIndex + 1;
      setPage((prevPage) => prevPage + 1);
    }

    try {
      setLoading(true);
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const res = await api.get(
        `/establishments?orderBy=${alphabeticFilter}&page=${pageIndex}&limit=5`,
        {
          headers,
        },
      );

      if (resetPage) {
        setEstablishment(res.data.results);
      } else {
        setEstablishment([...establishment, ...res.data.results]);
      }

      setFilterMachineApi(false);
      setFilterAlphabeticApi(true);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      setLoading(false);
    }
  };

  const changeFilterMachine = async (
    value: string,
    resetPage = false,
    addPage = false,
  ) => {
    let paymentMahine = false;
    if (value === 'Maquininha Payment') {
      paymentMahine = true;
    } else {
      paymentMahine = false;
    }

    let pageIndex = page;

    if (resetPage) {
      pageIndex = 1;
      setPage(1);
    }

    if (addPage) {
      pageIndex = pageIndex + 1;
      setPage((prevPage) => prevPage + 1);
    }

    try {
      setLoading(true);
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const res = await api.get(
        `/establishments?presentialPayment=${paymentMahine}&page=${pageIndex}&limit=5`,
        {
          headers,
        },
      );

      if (resetPage) {
        setEstablishment(res.data.results);
      } else {
        setEstablishment([...establishment, ...res.data.results]);
      }

      setFilterAlphabeticApi(false);
      setFilterMachineState(value);
      setFilterMachineApi(true);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      setLoading(false);
    }
  };

  const updatePaymentMahineSwitcher = async (
    id: string,
    cardMachine: boolean,
    setCardMachine: Dispatch<SetStateAction<boolean>>,
    setLoadingCardMachine: Dispatch<SetStateAction<boolean>>,
  ) => {
    try {
      setLoadingCardMachine(true);
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.patch(
        `/establishments/${id}/payment-machine`,
        {
          presentialPayment: cardMachine,
        },
        {
          headers,
        },
      );
      setCardMachine(cardMachine);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoadingCardMachine(false);
    }
  };

  const searchEstablishments = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    if (establishmentSearch.trim() !== '') {
      const res = await api.get(`/establishments?name=${establishmentSearch}`, {
        headers,
      });
      setEstablishment(res.data.results);
    } else {
      const res = await api.get(`/establishments`, {
        headers,
      });
      setEstablishment(res.data.results);
    }
  };

  const handleNotificationSend = async (notificationItems: any) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    try {
      await api.post(
        `/notifications/push`,
        {
          title: notificationItems.title,
          content: notificationItems.content,
          categoryId: notificationItems.categoryId,
          radius: notificationItems.radius,
        },
        {
          headers,
        },
      );
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const searchEstablishmentsByCity = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    if (establishmentSearchByCity.trim() !== '') {
      const res = await api.get(
        `/establishments?city=${establishmentSearchByCity}`,
        {
          headers,
        },
      );
      setEstablishment(res.data.results);
    } else {
      const res = await api.get(`/establishments`, {
        headers,
      });
      setEstablishment(res.data.results);
    }
  };

  return (
    <EstablishmentContext.Provider
      value={{
        establishment,
        notificationsInfos,
        setNotificationsInfos,
        setEstablishment,
        establishmentSearch,
        setEstablishmentSearch,
        searchEstablishments,
        filterAlphabetic,
        filterAlphabeticApi,
        filterMachineState,
        establishmentSearchByCity,
        setEstablishmentSearchByCity,
        setFilterMachineState,
        setFilterAlphabeticApi,
        setFilterAlphabetic,
        filterMachineApi,
        setFilterMachineApi,
        fetchEstablishment,
        changeFilterMachine,
        updatePaymentMahineSwitcher,
        isModalOpen,
        setIsModalOpen,
        handleNotificationSend,
        loading,
        page,
        setPage,
        setLoading,
        total,
        searchEstablishmentsByCity,
        setTotal,
      }}
    >
      {children}
    </EstablishmentContext.Provider>
  );
};

function useEstablishment(): EstablishmentContextData {
  const context = useContext(EstablishmentContext);

  if (!context) {
    throw new Error(
      'useEstablishment must be used within an EstablishmentProvider',
    );
  }

  return context;
}

export { EstablishmentProvider, useEstablishment };
