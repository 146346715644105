import { Dispatch, HTMLAttributes, SetStateAction } from 'react';

import { useAuth } from '../../../hooks/Auth';

import menu from '../../icons/menu.png';
import graph from '../../icons/graph.png';
import editUser from '../../icons/edit-user.png';
import settings from '../../icons/settings.png';
import logo from '../../icons/logo.png';
import { FaPowerOff } from 'react-icons/fa';

import { Container, MenuIcon, Items, Logo } from './_SideBar';
import { Link } from 'react-router-dom';

interface Props extends HTMLAttributes<HTMLDivElement> {
  showSideBar: boolean;
  setShowSideBar: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
}

export const SideBar = ({
  showSideBar,
  setShowSideBar,
  showMenu,
}: Props) => {
  const actualPath = window.location.pathname;

  const { signOut } = useAuth();

  const renderMenu = () => {
    if (showMenu) {
      return (
        <Items>
          <Link to="/">
            <img src={graph} alt="Ícone de gráfico" className={actualPath === '/dashboard' ? 'active' : ''}/>
          </Link>
          <Link to="/cadastros">
            <img src={editUser} alt="Ícone de usuário" className={actualPath === '/cadastros' ? 'active' : ''}/>
          </Link>
          <Link to="/configuracao">
            <img src={settings} alt="Ícone de configurações" className={actualPath === '/configuracao' ? 'active' : ''}/>
          </Link>
          <span onClick={() => signOut()} style={{ cursor: 'pointer' }}>
            <FaPowerOff color="#fff" size={35} />
          </span>
        </Items>
      );
    }
  };

  return (
    <Container showSideBar={showSideBar} showMenu={showMenu}>
      <MenuIcon
        onClick={() => setShowSideBar(false)}
        src={menu}
        alt="Ícone de menu"
      />
      {renderMenu()}
      <Link to="/">
        <Logo src={logo} alt="Logo do Payment" />
      </Link>
    </Container>
  );
};
