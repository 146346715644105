import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 16.2rem;
  gap: 10rem;
  margin-top: 4rem;

  h1 {
    font: 1.8rem 'FontsFreeNetSFProTextMedium', sans-serif;
    color: #8E8E93;
    margin-bottom: 2.5rem;
  }

  @media(max-width: 1100px) {
    gap: 5rem;
  }

  @media(max-width: 1020px) {
    margin-top: 0rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    h1 {
      font-size: 1.4rem;
      text-align: center;
    }
  }
`;
