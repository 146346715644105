import styled from 'styled-components';

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  gap: 22px;

  @media (max-width: 1020px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 471px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 31px;
  padding: 3rem 2rem;

  h2 {
    font: 1.4rem 'FontsFreeNetSFProTextLight', sans-serif;
    color: var(--main);
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-top: auto;
    }
  }

  input,
  textarea {
    max-width: 265px;
    height: 50px;
    border: none;
    box-shadow: 0px 2px 6px #00000030;
    border-radius: 10px;
    padding: 11px 3rem 11px 1rem;

    &::placeholder {
      color: #d1d1d6;
      font: 1.2rem 'FontsFreeNetSFProTextLight', sans-serif;
    }
  }

  textarea {
    height: 155px;
    max-width: 100%;
    resize: none;
  }
`;

export const SendConfig = styled(Content)`
  background-color: #efeff4;
  box-shadow: 0px 3px 6px #00000029;
  padding: 3rem 2rem 1rem;
  max-height: 440px;

  h2 {
    color: #8e8e93;
    text-align: center;

    &:last-of-type {
      margin-top: 0;
    }
  }

  span {
    font: 0.8rem 'FontsFreeNetSFProTextLight', sans-serif;
    color: #8e8e93;
  }

  input,
  .ant-select {
    max-height: 32px;
    max-width: 100%;
    border-radius: 30px;
    margin: 10px 0 14px;
    box-shadow: 0px 2px 6px #00000030;

    &::placeholder {
      font: 0.8rem 'FontsFreeNetSFProTextLight', sans-serif;
    }
  }

  > button {
    width: 100%;
    max-width: 210px;
    max-height: 29px;
    border-radius: 28px;
    margin: 1rem auto;
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const Input = styled.div`
  position: relative;
  max-width: 100%;

  svg {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 1.2rem;
    color: var(--main);
  }
`;

export const ContainerRange = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 14px;
  background-color: #f6f6f9;
  padding: 0.8rem;
  border-radius: 16px;
`;

export const SelectorsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Selector = styled.section`
  display: flex;
  align-items: center;
  width: 100%;

  hr {
    width: 100%;
    border: none;
    background-color: #ff2d55;
    height: 3px;
  }

  svg {
    font-size: 2rem;
    color: #ff2d55;
    cursor: pointer;
  }

  &:first-child {
    width: min-content;

    svg {
      font-size: 1.2rem;
    }
  }

  &.active {
    hr {
      background-color: var(--main);
    }

    svg {
      color: var(--main);
    }
  }
`;

export const Subtitle = styled.section`
  display: flex;
  justify-content: space-evenly;
  text-align: center;

  span {
    font: 0.7rem 'FontsFreeNetSFProTextLight', sans-serif;
    max-width: 30px;
  }
`;
